<script setup>
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';

const props = defineProps({
  botId: {
    type: Number,
    required: true,
  },
  tool: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);
const { t } = useI18n();
const store = useStore();

const isOpen = ref(false);
const dialogRef = ref(null);

const uiFlags = useMapGetter('wgptBotTools/getUIFlags');

const handleSubmit = async () => {
  try {
    const deleteOptions = {
      botId: props.botId,
      id: props.tool.id,
    };
    await store.dispatch('wgptBotTools/delete', deleteOptions);

    const successMessage = t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.DELETE_TOOL_SET.API.SUCCESS_MESSAGE'
    );
    useAlert(successMessage);
    dialogRef.value.close();
    emit('close');
  } catch (error) {
    const errorMessage =
      error?.message ||
      t('WGPT_BOTS.EDIT.TABS.TOOLS.DELETE_TOOL_SET.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const handleOpen = () => {
  isOpen.value = true;
};

const handleDismiss = () => {
  isOpen.value = false;
  emit('close');
};

const dismiss = () => {
  dialogRef.value.close();
  handleDismiss();
};

const open = () => {
  handleOpen();
  nextTick(() => {
    dialogRef.value?.open();
  });
};

defineExpose({ open, dismiss });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="alert"
    :title="t('WGPT_BOTS.EDIT.TABS.TOOLS.DELETE_TOOL_SET.CONFIRM.TITLE')"
    :description="
      t('WGPT_BOTS.EDIT.TABS.TOOLS.DELETE_TOOL_SET.CONFIRM.MESSAGE', {
        name: tool.name,
      })
    "
    :is-loading="uiFlags.isUpdating"
    :disable-confirm-button="uiFlags.isUpdating"
    :confirm-button-label="
      t('WGPT_BOTS.EDIT.TABS.TOOLS.DELETE_TOOL_SET.CONFIRM.YES')
    "
    :cancel-button-label="
      t('WGPT_BOTS.EDIT.TABS.TOOLS.DELETE_TOOL_SET.CONFIRM.NO')
    "
    @confirm="handleSubmit"
    @close="handleDismiss"
  />
</template>
