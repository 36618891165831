<script>
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import { requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  mixins: [translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      updatedValue: this.value ?? this.defaultValue ?? '',
      options: [
        {
          label: this.$t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.SELECT.BOOLEAN_TRUE_OPTION'
          ),
          value: true,
        },
        {
          label: this.$t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.SELECT.BOOLEAN_FALSE_OPTION'
          ),
          value: false,
        },
      ],
    };
  },
  computed: {
    defaultOptionLabel() {
      if (this.placeholder) return this.t(this.placeholder);
      if (this.required)
        return this.$t(
          'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.SELECT.DEFAULT_OPTION_REQUIRED'
        );
      return this.$t(
        'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.SELECT.DEFAULT_OPTION'
      );
    },
  },
  validations() {
    return {
      updatedValue: {
        required: requiredIf(this.required),
      },
    };
  },
  watch: {
    value(value) {
      this.updatedValue = value ?? '';
    },
  },
  methods: {
    updateValue(e) {
      const rawValue = e.target.value;
      const value = this.formatSelectValue(e.target.value);
      this.updatedValue = rawValue;
      this.$emit('change', value);
    },
    formatSelectValue(value) {
      if (value === '') return null;

      return value === 'true';
    },
  },
};
</script>

<template>
  <div>
    <label class="select-label" :class="{ error: v$.updatedValue.$error }">
      <div class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </div>

      <select
        v-model="updatedValue"
        :disabled="disabled"
        class="cursor-pointer disabled:cursor-not-allowed truncate !mb-4"
        :class="updatedValue === '' ? 'placeholder' : ''"
        @input="updateValue"
      >
        <option value="" :disabled="required" selected>
          {{ defaultOptionLabel }}
        </option>
        <option
          v-for="(option, index) of options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>

      <span v-if="v$.updatedValue.$error" class="message">
        <template v-if="v$.updatedValue.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </label>
  </div>
</template>

<style scoped>
.placeholder {
  @apply text-slate-400 dark:text-slate-400;
}
</style>
