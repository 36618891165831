<script setup>
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToggle } from '@vueuse/core';
import { useStoreGetters, useMapGetter } from 'dashboard/composables/store';
import { useRouter } from 'vue-router';
import { useAlert } from 'dashboard/composables';
import { useStore } from 'vuex';

import Spinner from 'dashboard/components-next/spinner/Spinner.vue';
import CampaignLayout from 'dashboard/components-next/Campaigns/CampaignLayout.vue';
import CampaignList from './CampaignList.vue';
import CreateCampaignDialog from './create/CreateCampaignDialog.vue';
import DeleteCampaignDialog from './delete/DeleteCampaignDialog.vue';
import StopCampaignDialog from './stop/StopCampaignDialog.vue';
import CampaignEmptyState from './CampaignEmptyState.vue';

const { t } = useI18n();
const store = useStore();
const getters = useStoreGetters();
const router = useRouter();

onMounted(() => {
  store.dispatch('wgptCampaigns/get');
  store.dispatch('wgptAutomations/get');
});

const selectedCampaign = ref({});
const stopDialogRef = ref(null);
const deleteCampaignDialogRef = ref(null);

const [showCampaignDialog, toggleCampaignDialog] = useToggle();

const uiFlags = useMapGetter('wgptCampaigns/getUIFlags');
const isFetchingCampaigns = computed(() => uiFlags.value.isFetching);

const workflowCampaigns = computed(() => {
  const campaigns = getters['wgptCampaigns/getCampaigns'].value;
  return campaigns.map(campaign => ({
    ...campaign,
    inbox: {},
  }));
});

const hasNoCampaigns = computed(
  () => workflowCampaigns.value?.length === 0 && !isFetchingCampaigns.value
);

const handleDelete = campaign => {
  selectedCampaign.value = campaign;
  deleteCampaignDialogRef.value.dialogRef.open();
};

const handleEdit = campaign => {
  router.push({
    name: 'wgpt_campaigns_editor',
    params: { campaignId: campaign.id },
    query: { selected: 'settings' },
  });
};

const handleClone = async campaign => {
  try {
    await store.dispatch('wgptCampaigns/clone', campaign.id);

    useAlert(t('WGPT_CAMPAIGNS.CLONE.API.SUCCESS_MESSAGE'));
  } catch (error) {
    const errorMessage =
      error?.response?.message || t('WGPT_CAMPAIGNS.CLONE.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const handleStop = async campaign => {
  selectedCampaign.value = campaign;
  stopDialogRef.value?.open();
};
</script>

<template>
  <CampaignLayout
    :header-title="t('WGPT_CAMPAIGNS.HEADER_TITLE')"
    :button-label="t('WGPT_CAMPAIGNS.NEW_CAMPAIGN')"
    @click="toggleCampaignDialog()"
    @close="toggleCampaignDialog(false)"
  >
    <template #action>
      <CreateCampaignDialog
        v-if="showCampaignDialog"
        @close="toggleCampaignDialog(false)"
      />
    </template>
    <div
      v-if="isFetchingCampaigns"
      class="flex items-center justify-center py-10 text-n-slate-11"
    >
      <Spinner />
    </div>
    <CampaignList
      v-else-if="!hasNoCampaigns"
      :campaigns="workflowCampaigns"
      @delete="handleDelete"
      @edit="handleEdit"
      @clone="handleClone"
      @stop="handleStop"
    />
    <CampaignEmptyState
      v-else
      :title="t('WGPT_CAMPAIGNS.EMPTY_STATE.TITLE')"
      :subtitle="t('WGPT_CAMPAIGNS.EMPTY_STATE.SUBTITLE')"
      class="pt-14"
    />
    <StopCampaignDialog
      ref="stopDialogRef"
      :selected-campaign="selectedCampaign"
    />
    <DeleteCampaignDialog
      ref="deleteCampaignDialogRef"
      :selected-campaign="selectedCampaign"
    />
  </CampaignLayout>
</template>
