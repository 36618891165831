<script setup>
import { reactive, watch, computed } from 'vue';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import Input from 'next/input/Input.vue';
import TextAreaNext from 'next/textarea/TextArea.vue';

const props = defineProps({
  tool: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const store = useStore();

const toolList = computed(() => store.getters['wgptBotTools/getToolSets']);

const alphanumericUnderscore = helpers.regex(/^[a-z0-9_]*$/);

const isUniqueName = value => {
  if (!value) return true;
  // Exclude current tool's name from unique check
  return !toolList.value.some(
    tool => tool.name === value && tool.id !== props.tool.id
  );
};

const rules = {
  name: {
    required,
    alphanumericUnderscore,
    isUnique: helpers.withMessage(
      () => t('WGPT_BOTS.EDIT.TABS.TOOLS.EDIT_TOOL_SET.FORM.NAME.ERROR_UNIQUE'),
      isUniqueName
    ),
  },
  description: {},
};

const state = reactive({
  name: props.tool.name || '',
  description: props.tool.description || '',
});

const v$ = useVuelidate(rules, state);

const errorMessage = computed(() => {
  if (!v$.value.name.$error) return '';
  if (v$.value.name.alphanumericUnderscore.$invalid) {
    return t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.EDIT_TOOL_SET.FORM.NAME.ERROR_ALPHANUMERIC'
    );
  }
  if (v$.value.name.isUnique.$invalid) {
    return t('WGPT_BOTS.EDIT.TABS.TOOLS.EDIT_TOOL_SET.FORM.NAME.ERROR_UNIQUE');
  }
  return t('WGPT_BOTS.EDIT.TABS.TOOLS.EDIT_TOOL_SET.FORM.NAME.ERROR');
});

watch(
  () => state.name,
  newValue => {
    state.name = newValue.toLowerCase().replace(/\s+/g, '_');
    v$.value.name.$touch();
  }
);

const validate = () => {
  return v$.value.$validate();
};

defineExpose({
  state,
  validate,
});
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="validate">
    <label>
      {{ $t('WGPT_BOTS.EDIT.TABS.TOOLS.EDIT_TOOL_SET.FORM.NAME.LABEL') }}
      <Input
        v-model.trim="state.name"
        type="text"
        data-testid="tool-name"
        :message="errorMessage"
        :message-type="v$.name.$error ? 'error' : 'info'"
      />
    </label>

    <label>
      {{ $t('WGPT_BOTS.EDIT.TABS.TOOLS.EDIT_TOOL_SET.FORM.DESCRIPTION.LABEL') }}
      <TextAreaNext
        v-model.trim="state.description"
        custom-text-area-class="!outline !outline-0"
        data-testid="tool-description"
        rows="3"
      />
    </label>
  </form>
</template>
