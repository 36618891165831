export const BOT_TOOLS_EMPTY_STATE_CONTENT = [
  {
    name: 'Smart Scheduler',
    description: 'Organize meetings and manage appointments seamlessly.',
  },
  {
    name: 'PayLink Generator',
    description:
      'Create and distribute secure payment links effortlessly. PayLink Generator simplifies transaction requests, allowing for quick processing and tracking of payments in real-time.',
  },
  {
    name: 'Check inventory',
    description: 'Validate product availability and manage stock levels.',
  },
];
