<script setup>
import { computed, reactive, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

import DynamicField from '../dynamicField/Index.vue';

const props = defineProps({
  inputMapping: {
    type: Object,
    default: () => ({}),
  },
  inputMappingFields: {
    type: Array,
    default: () => [],
  },
  variables: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['change']);

const { t: $t } = useI18n();

const state = reactive({
  updatedInputMapping: props.inputMapping,
});

const getVariables = (inputMapping, inputMappingFields) => {
  const values = [];
  if (Array.isArray(inputMapping)) {
    inputMapping.forEach(inputMappingItem => {
      const inputMappingFieldItem = inputMappingFields?.controls?.[0]?.controls;
      values.push(...getVariables(inputMappingItem, inputMappingFieldItem));
    });
  } else if (typeof inputMapping === 'object') {
    Object.keys(inputMapping).forEach(key => {
      const inputMappingValue = inputMapping[key];
      const inputMappingFieldItem = inputMappingFields.find(
        ({ name }) => name === key
      );
      values.push(...getVariables(inputMappingValue, inputMappingFieldItem));
    });
  } else if (inputMappingFields.is_variable) {
    values.push(inputMapping);
  }
  return values;
};

const rules = {
  updatedInputMapping: {
    duplicate: value => {
      const { inputMappingFields, variables } = props;
      const inheritedVariables = variables.map(({ name }) => name);
      const inputMappingVariables = getVariables(value, inputMappingFields);
      const allVariables = [...inheritedVariables, ...inputMappingVariables];
      const duplicateVariables = allVariables
        .filter(v => v)
        .filter((item, i, items) => items.indexOf(item) !== i);
      return duplicateVariables.length === 0;
    },
  },
};

const v$ = useVuelidate(rules, state);

const showInputMappingFields = computed(() => {
  return props.inputMappingFields.length > 0;
});

watch(
  () => props.inputMapping,
  value => {
    state.updatedInputMapping = value;
  },
  { immediate: true }
);

const getField = fieldName => {
  return props.inputMappingFields.find(({ name }) => name === fieldName);
};

const isVisible = field => {
  if (!field.show_if) return true;

  return field.show_if.every(({ field: fieldName, value }) => {
    const currentValue = state.step[fieldName] || getField(fieldName)?.default;
    if (!currentValue && currentValue !== 0 && currentValue !== false)
      return false;
    if (value.includes('*')) {
      return `${currentValue}`.includes(value.replace(/\*/g, ''));
    }
    return currentValue === value;
  });
};

const onInputMappingFieldChange = (key, value) => {
  const inputMappingBefore = state.updatedInputMapping ?? {};
  const inputMapping = {
    ...inputMappingBefore,
    [key]: value,
  };
  state.updatedInputMapping = inputMapping;
  v$.value.updatedInputMapping.$touch();
  emit('change', inputMapping);
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="showInputMappingFields" class="input-mapping-fields mt-8">
    <div class="rounded-md" :class="{ error: v$.updatedInputMapping.$error }">
      <DynamicField
        v-for="(field, index) of inputMappingFields"
        :key="index"
        :field="field"
        :value="state.updatedInputMapping[field.name]"
        :visible="isVisible(field)"
        :show-variables-selector="false"
        @change="value => onInputMappingFieldChange(field.name, value)"
      />

      <span v-if="v$.updatedInputMapping.$error" class="message">
        <template v-if="v$.updatedInputMapping.duplicate.$invalid">{{
          $t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.INPUT_MAPPING.ERRORS.DUPLICATE'
          )
        }}</template>
        <template v-else>{{
          $t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.INPUT_MAPPING.ERRORS.INVALID'
          )
        }}</template>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  @apply p-2 my-2 bg-red-50 dark:bg-red-800/50 border border-solid border-red-100 dark:border-red-700/50 mb-4;
}
</style>
