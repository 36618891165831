<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import ToolSetList from './ToolSetList.vue';
import ToolEditor from './edit/ToolEditor.vue';

const props = defineProps({
  bot: {
    type: Object,
    default: () => ({}),
  },
});

const router = useRouter();

const selectedToolSet = ref(null);
const selectedTool = ref(null);

const showEditor = computed(() => {
  return selectedToolSet.value && selectedTool.value;
});

const selectTool = (toolSet, tool) => {
  router.replace({
    name: 'wgpt_bots_edit_tool',
    params: { toolSetId: toolSet.id, toolId: tool.id },
    query: { selected: 'settings' },
  });
  selectedToolSet.value = toolSet;
  selectedTool.value = tool;
};

const setDefaults = () => {
  router.replace({ name: 'wgpt_bots_edit', props: { bot: props.bot.id } });
  selectedToolSet.value = null;
  selectedTool.value = null;
};

const back = () => {
  setDefaults();
};

onMounted(() => {
  setDefaults();
});
</script>

<template>
  <ToolEditor
    v-if="showEditor"
    :tool-set="selectedToolSet"
    :tool-id="selectedTool.id"
    :bot="bot"
    @back="back"
  />
  <ToolSetList v-show="!showEditor" :bot="bot" @select-tool="selectTool" />
</template>
