import { helpers } from '@vuelidate/validators';
import translateMixin from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';

function getUniqueFieldKeys(fields, prefix = null) {
  const fieldNames = [];
  fields.forEach(field => {
    if (field.type === 'group') {
      const childFieldNames = getUniqueFieldKeys(field.controls, field.name);
      fieldNames.push(
        ...childFieldNames.map(childFieldName => {
          if (prefix) return `${prefix}.${childFieldName}`;
          return childFieldName;
        })
      );
      return;
    }

    if (field.unique) fieldNames.push(field.name);
  });
  return fieldNames;
}

function getValue(field, fieldPath) {
  return fieldPath.split('.').reduce((o, k) => (o || {})[k], field);
}

const duplicateIf = (condition, dynamicField) => arrayValue => {
  try {
    if (!condition) return true;
    if (!Array.isArray(arrayValue)) return true;

    const dynamicArrayFieldControl = dynamicField.controls[0];
    if (dynamicArrayFieldControl.type === 'group') {
      const uniqueKeys = getUniqueFieldKeys(dynamicArrayFieldControl.controls);
      if (uniqueKeys.length === 0) return true;

      const duplicateUniqueKeys = uniqueKeys.filter(uniqueKey => {
        const values = arrayValue
          .map(item => getValue(item, uniqueKey))
          .filter(value => value);
        const hasDuplicate = new Set(values).size !== values.length;
        return hasDuplicate;
      });
      return duplicateUniqueKeys.length === 0;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export default {
  mixins: [translateMixin],
  methods: {
    duplicateIf(condition, dynamicArrayField) {
      return helpers.withMessage(
        ({ $model: arrayValue }) => {
          try {
            if (!condition) return null;
            if (!Array.isArray(arrayValue)) return null;

            const dynamicArrayFieldControl = dynamicArrayField.controls[0];
            if (dynamicArrayFieldControl.type === 'group') {
              const uniqueKeys = getUniqueFieldKeys(
                dynamicArrayFieldControl.controls
              );
              if (uniqueKeys.length === 0) return null;

              const duplicateUniqueKeys = uniqueKeys.filter(uniqueKey => {
                const values = arrayValue.map(item =>
                  getValue(item, uniqueKey)
                );
                const hasDuplicate = new Set(values).size !== values.length;
                return hasDuplicate;
              });

              const duplicateUniqueKeysLabels = duplicateUniqueKeys.map(
                duplicateUniqueKey => {
                  const label = dynamicArrayFieldControl.controls.find(
                    control => control.name === duplicateUniqueKey
                  ).label;
                  const defaultFallback = arrayValue
                    .map(item => getValue(item, duplicateUniqueKey))
                    .filter((value, _index, values) => {
                      const duplicateValues = values.filter(v => v === value);
                      return duplicateValues.length > 1;
                    })
                    .filter((value, index, values) => {
                      return values.indexOf(value) === index;
                    });
                  return this.t(label, defaultFallback);
                }
              );

              return this.$t(
                'WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.DUPLICATE',
                { keys: duplicateUniqueKeysLabels.join(', ') + ' ' }
              );
            }

            return this.$t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.DUPLICATE', {
              keys: '',
            });
          } catch (error) {
            return this.$t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID');
          }
        },
        duplicateIf(condition, dynamicArrayField)
      );
    },
  },
};
