<script>
import { mapGetters } from 'vuex';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import getUuid from 'widget/helpers/uuid';
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
  },
  mixins: [translateMixins],
  emits: ['close', 'add'],
  computed: {
    ...mapGetters({
      registryActions: 'wgptAutomationRegistry/getActions',
    }),
    actions() {
      const actions = this.registryActions;
      const actionNames = actions.map(({ action }) => action);
      const baseActionNames = actionNames.map(actionName => {
        const parts = actionName.split('_');
        const versionPart = parts[parts.length - 1];
        const hasVersion = /^v\d+$/.test(versionPart);
        return hasVersion ? parts.slice(0, -1).join('_') : actionName;
      });
      const uniqueBaseActionNames = baseActionNames.filter(
        (actionName, index) => baseActionNames.indexOf(actionName) === index
      );
      const latestVersionActions = uniqueBaseActionNames.map(actionName => {
        const actionRegex = new RegExp(`^${actionName}(_v\\d+)?$`);
        const versions = actions.filter(({ action }) =>
          actionRegex.test(action)
        );
        const latestVersion = versions.reduce(
          (latest, current) => {
            if (current.version > latest.version) {
              return current;
            }
            return latest;
          },
          { version: 0 }
        );
        return latestVersion;
      });
      return latestVersionActions;
    },
  },
  methods: {
    addBranch() {
      const step = {
        id: getUuid(),
        action: 'branch',
        branches: [
          {
            id: getUuid(),
            name: this.$t(
              'WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_BRANCH_NAME',
              {
                index: 1,
              }
            ),
            logical_operator: 'AND',
            conditions: [
              {
                variable_name: '',
                operator: 'equals',
                value: '',
              },
            ],
            steps: [],
          },
        ],
        default: {
          id: getUuid(),
          steps: [],
        },
      };

      this.$emit('close');
      this.$emit('add', step);
      this.$router.replace({ query: { selected: step.id } }).catch(() => {});
    },
    addAction(action) {
      const step = {
        id: getUuid(),
        action: action.action,
      };

      const formFields = action.form_fields ?? [];
      formFields.forEach(field => {
        if (Object.keys(field).includes('default')) {
          const stepAttributesBefore = step.attributes ?? {};
          step.attributes = {
            ...stepAttributesBefore,
            [field.name]: field.default,
          };
        }
      });

      const outputMappingFields = action.output_mapping_fields ?? [];
      outputMappingFields.forEach(field => {
        if (Object.keys(field).includes('default')) {
          const stepOutputMappingBefore = step.output_mapping ?? {};
          step.output_mapping = {
            ...stepOutputMappingBefore,
            [field.name]: field.default,
          };
        }
      });

      this.$emit('close');
      this.$emit('add', step);
      this.$router.replace({ query: { selected: step.id } }).catch(() => {});
    },
  },
};
</script>

<template>
  <div class="min-w-[300px]">
    <div
      class="ps-3 pe-2 py-2 flex justify-between items-center border-b border-slate-100 dark:border-slate-700/25"
    >
      <label class="font-bold">{{
        $t('WGPT_AUTOMATIONS.EDITOR.ADD_STEP_DROPDOWN.TITLE')
      }}</label>
      <woot-button
        variant="clear"
        size="tiny"
        icon="dismiss"
        color-scheme="secondary"
        @click="$emit('close')"
      />
    </div>

    <div class="p-1 w-full max-h-[14rem] overflow-auto">
      <WootDropdownMenu>
        <WootDropdownItem>
          <woot-button
            class="multiselect-dropdown--item px-2"
            variant="clear"
            color-scheme="secondary"
            @click="addBranch()"
          >
            <div class="flex items-center justify-between">
              <span class="truncate text-sm">
                {{ t('ACTIONS.BRANCH.NAME') }}
              </span>
              <fluent-icon
                v-tooltip.top="{
                  content: t('ACTIONS.BRANCH.DESCRIPTION'),
                  popperClass:
                    'wgpt-automations-add-step-dropdown-item-tooltip',
                  container: false,
                  boundary: 'body',
                }"
                icon="info"
                size="14"
                color-scheme="secondary"
              />
            </div>
          </woot-button>
        </WootDropdownItem>
        <WootDropdownItem v-for="(action, index) of actions" :key="index">
          <woot-button
            class="multiselect-dropdown--item px-2"
            variant="clear"
            color-scheme="secondary"
            @click="addAction(action)"
          >
            <div class="flex items-center justify-between">
              <span class="truncate text-sm">
                {{ t(action.name) }}
              </span>
              <fluent-icon
                v-if="action.description"
                v-tooltip.top="{
                  content: t(action.description),
                  popperClass:
                    'wgpt-automations-add-step-dropdown-item-tooltip',
                  container: false,
                  boundary: 'body',
                }"
                icon="info"
                size="14"
                color-scheme="secondary"
              />
            </div>
          </woot-button>
        </WootDropdownItem>
      </WootDropdownMenu>
    </div>
  </div>
</template>

<style lang="scss">
.wgpt-automations-add-step-dropdown-item-tooltip {
  max-width: 300px;
  white-space: wrap;
}
</style>
