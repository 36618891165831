<script>
import { useAlert } from 'dashboard/composables';
import FluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';
import Editor from 'next/wgpt/Automation/editor/Index.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';

export default {
  components: {
    Editor,
    FluentIcon,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showActionsDropdown: false,
      automation: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptAutomations/getUIFlags',
    }),
    automationId() {
      return Number(this.$route.params.automationId);
    },
    record() {
      return this.$store.getters['wgptAutomations/getAutomation'](
        this.automationId
      );
    },
    isAutomationActive() {
      return this.record.active;
    },
    triggerRegistry() {
      const trigger = this.automation.trigger || {};
      const triggerType = trigger.type;
      return this.$store.getters['wgptAutomationRegistry/getTrigger'](
        triggerType
      );
    },
    selectedStepId() {
      return this.$route.query.selected;
    },
    isLoading() {
      if (this.record.id === this.automationId) return false;
      return this.uiFlags.isFetchingItem;
    },
  },
  validations: {
    automation: {},
  },
  watch: {
    record: {
      handler(value) {
        this.automation = value;
      },
      immediate: true,
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.$store.dispatch('wgptAutomations/show', this.automationId);
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          throw new Error(
            this.$t('WGPT_AUTOMATIONS.EDITOR.API.INVALID_ERROR_MESSAGE')
          );
        }

        const id = this.automationId;
        const data = {
          name: this.automation.name,
          description: this.automation.description,
          trigger: this.cleanAttributes(
            this.automation.trigger,
            this.triggerRegistry
          ),
          steps: this.automation.steps,
        };
        await this.$store.dispatch('wgptAutomations/update', { id, ...data });
        useAlert(this.$t('WGPT_AUTOMATIONS.EDITOR.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    cleanAttributes(data, registry = {}) {
      const cleanData = {
        ...data,
        attributes: {},
      };
      const attributes = data.attributes ?? {};
      const formFields = registry.form_fields ?? [];
      formFields.forEach(field => {
        cleanData.attributes[field.name] = attributes[field.name];
      });
      return cleanData;
    },

    async toggleActiveStatus() {
      try {
        const id = this.automationId;
        const data = {
          active: !this.automation.active,
        };
        await this.$store.dispatch('wgptAutomations/update', { id, ...data });

        useAlert(this.$t('WGPT_AUTOMATIONS.EDITOR.API.SUCCESS_MESSAGE'));
        this.closeActionsDropdown();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    openActionsDropdown() {
      this.showActionsDropdown = true;
    },
    closeActionsDropdown() {
      this.showActionsDropdown = false;
    },
  },
};
</script>

<template>
  <div class="flex-1 flex overflow-hidden">
    <div class="absolute top-0 end-0 flex gap-2 m-3">
      <router-link
        :to="{ query: { selected: 'settings' } }"
        :class="{ 'settings-button-active': selectedStepId === 'settings' }"
        replace
      >
        <woot-button
          color-scheme="secondary"
          icon="settings"
          variant="clear"
          size="small"
          class-names="settings-button"
        />
      </router-link>

      <woot-button color-scheme="primary" size="small" @click="onSubmit">
        {{ $t('WGPT_AUTOMATIONS.EDITOR.SAVE') }}
      </woot-button>

      <div class="relative">
        <div class="button-group">
          <woot-button
            class-names="publish-button"
            size="small"
            icon="checkmark"
            color-scheme="success"
            :disabled="isAutomationActive"
            @click="toggleActiveStatus()"
          >
            {{ $t('WGPT_AUTOMATIONS.EDITOR.ACTIVATE') }}
          </woot-button>
          <woot-button
            class-names="publish-option-button"
            size="small"
            icon="chevron-down"
            color-scheme="success"
            :disabled="!isAutomationActive"
            @click="openActionsDropdown"
          />
        </div>
        <div
          v-if="showActionsDropdown"
          v-on-clickaway="closeActionsDropdown"
          class="dropdown-pane dropdown-pane--open end-0"
        >
          <woot-dropdown-menu>
            <woot-dropdown-item>
              <woot-button
                variant="clear"
                color-scheme="secondary"
                size="small"
                @click="toggleActiveStatus()"
              >
                <FluentIcon icon="prohibited" size="16" />
                {{ $t('WGPT_AUTOMATIONS.EDITOR.DEACTIVATE') }}
              </woot-button>
            </woot-dropdown-item>
          </woot-dropdown-menu>
        </div>
      </div>
    </div>

    <Editor v-if="!isLoading" v-model="automation" class="flex-1" />
  </div>
</template>

<style scoped>
.settings-button-active .settings-button {
  @apply outline-none;
  outline-color: #1f93ff;
  outline-offset: 1px;
}

[dir='rtl'] .publish-button {
  @apply rounded-e-none rounded-s-md;
}

[dir='rtl'] .publish-option-button {
  @apply rounded-e-md rounded-s-none;
}
</style>
