<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useMapGetter } from 'dashboard/composables/store';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables';
import { BOT_TOOLS_EMPTY_STATE_CONTENT } from './helpers/emptyState';

import CreateToolSetDialog from './create/CreateToolSetDialog.vue';
import EditToolSetDialog from './edit/EditToolSetDialog.vue';
import DeleteToolSetDialog from './delete/DeleteToolSetDialog.vue';
import CreateToolDialog from './create/CreateToolDialog.vue';
import DeleteToolDialog from './delete/DeleteToolDialog.vue';
import ToolSetListLayout from './ToolSetListLayout.vue';
import ToolSetCard from './ToolSetCard.vue';
import Button from 'next/button/Button.vue';
import EmptyStateLayout from 'next/EmptyStateLayout.vue';

const props = defineProps({
  bot: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selectTool']);

const store = useStore();
const { t } = useI18n();

const createToolSetDialogRef = ref(null);
const editToolSetDialogRef = ref(null);
const deleteToolSetDialogRef = ref(null);
const createToolDialogRef = ref(null);
const deleteToolDialogRef = ref(null);
const selectedToolSet = ref({});
const selectedTool = ref({});

const uiFlags = useMapGetter('wgptBotTools/getUIFlags');
const toolSetList = computed(() => store.getters['wgptBotTools/getToolSets']);

const handleCreateToolSet = () => {
  createToolSetDialogRef.value?.open();
};

const handleEditToolSet = toolSet => {
  selectedToolSet.value = toolSet;
  editToolSetDialogRef.value?.open();
};

const handleDeleteToolSet = toolSet => {
  selectedToolSet.value = toolSet;
  deleteToolSetDialogRef.value?.open();
};

const handleToolSetToggle = async (toolSet, active) => {
  try {
    const updateOptions = {
      botId: props.bot.id,
      id: toolSet.id,
      active,
    };
    await store.dispatch('wgptBotTools/update', updateOptions);
  } catch (error) {
    const errorMessage =
      error?.response?.message ||
      t('WGPT_BOTS.EDIT.TABS.TOOLS.ACTIVE.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const handleCreateTool = toolSet => {
  selectedToolSet.value = toolSet;
  createToolDialogRef.value?.open();
};

const handleEditTool = (toolSet, tool) => {
  emit('selectTool', toolSet, tool);
};

const handleDeleteTool = (toolSet, tool) => {
  selectedToolSet.value = toolSet;
  selectedTool.value = tool;
  deleteToolDialogRef.value?.open();
};

const handleCloneTool = async (toolSet, tool) => {
  try {
    const cloneOptions = {
      botId: props.bot.id,
      toolSetId: toolSet.id,
      id: tool.id,
    };
    await store.dispatch('wgptBotTools/cloneTool', cloneOptions);

    const successMessage = t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.CLONE_TOOL.API.SUCCESS_MESSAGE'
    );
    useAlert(successMessage);
  } catch (error) {
    const errorMessage =
      error?.message ||
      t('WGPT_BOTS.EDIT.TABS.TOOLS.CLONE_TOOL.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const expandedCardId = ref(null);

const toggleExpanded = id => {
  expandedCardId.value = expandedCardId.value === id ? null : id;
};

onMounted(() => {
  store.dispatch('wgptBotTools/get', props.bot.id);
});
</script>

<template>
  <ToolSetListLayout>
    <template #start>
      <div class="flex items-center gap-5">
        <!-- <Breadcrumb :items="breadcrumbItems" /> -->
      </div>
    </template>
    <template #end>
      <Button
        :label="t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.HEADER.CREATE_TOOL_SET')"
        :is-loading="uiFlags.isCreating"
        :disabled="uiFlags.isCreating"
        size="sm"
        @click="handleCreateToolSet"
      />
    </template>

    <div v-if="toolSetList.length" class="pb-8">
      <ToolSetCard
        v-for="toolSet in toolSetList"
        :key="toolSet.id"
        :tool-set="toolSet"
        :is-expanded="expandedCardId === toolSet.id"
        :is-updating="uiFlags.isCreating"
        @toggle="toggleExpanded(toolSet.id)"
        @edit="handleEditToolSet(toolSet)"
        @delete="handleDeleteToolSet(toolSet)"
        @activate="handleToolSetToggle(toolSet, true)"
        @deactivate="handleToolSetToggle(toolSet, false)"
        @edit-tool="tool => handleEditTool(toolSet, tool)"
        @delete-tool="tool => handleDeleteTool(toolSet, tool)"
        @clone-tool="tool => handleCloneTool(toolSet, tool)"
        @create-tool="handleCreateTool(toolSet)"
      />
    </div>
    <div v-else class="w-full py-8 flex flex-col gap-2 items-center">
      <EmptyStateLayout
        :title="t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.EMPTY_STATE.TITLE')"
        :subtitle="t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.EMPTY_STATE.SUBTITLE')"
      >
        <template #empty-state-item>
          <div class="flex flex-col p-px">
            <ToolSetCard
              v-for="(tool, index) of BOT_TOOLS_EMPTY_STATE_CONTENT"
              :key="index"
              :tool-set="tool"
            />
          </div>
        </template>
      </EmptyStateLayout>
    </div>

    <DeleteToolSetDialog
      ref="deleteToolSetDialogRef"
      :bot-id="bot.id"
      :tool="selectedToolSet"
    />

    <EditToolSetDialog
      ref="editToolSetDialogRef"
      :bot-id="bot.id"
      :tool="selectedToolSet"
    />

    <CreateToolSetDialog ref="createToolSetDialogRef" :bot-id="bot.id" />

    <CreateToolDialog
      ref="createToolDialogRef"
      :bot-id="bot.id"
      :tool-set="selectedToolSet"
    />

    <DeleteToolDialog
      ref="deleteToolDialogRef"
      :bot-id="bot.id"
      :tool-set="selectedToolSet"
      :tool="selectedTool"
    />
  </ToolSetListLayout>
</template>
