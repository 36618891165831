<script>
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import { useVuelidate } from '@vuelidate/core';

export default {
  mixins: [translateMixins],
  props: {
    values: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    valueType: {
      type: String,
      default: 'string',
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    deselectLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const values = this.values ?? this.defaultValue;
    return {
      multiselectValues: this.extractValues(values),
    };
  },
  validations() {
    return {
      multiselectValues: {
        required: multiselectValues => {
          if (!this.required) return true;

          return multiselectValues.length > 0;
        },
      },
    };
  },
  watch: {
    values(values) {
      this.multiselectValues = this.extractValues(values);
    },
    options() {
      this.multiselectValues = this.extractValues(this.values);
    },
  },
  methods: {
    applyValueType(value) {
      if (this.valueType === 'number') {
        return Number(value);
      }
      if (this.valueType === 'boolean') {
        return value === 'true';
      }
      if (this.valueType === 'date') {
        return new Date(value);
      }
      return value;
    },
    extractValues(values) {
      return values
        .map(value => {
          return this.options.find(option => option.value === value);
        })
        .filter(value => value);
    },
    updateValue(e) {
      const updatedValues = e.map(({ value }) => this.applyValueType(value));
      this.multiselectValues = this.extractValues(updatedValues);
      this.v$.multiselectValues.$touch();
      this.$emit('change', updatedValues);
    },
    closeMultiselect() {
      this.$refs.multiselection.deactivate();
    },
  },
};
</script>

<template>
  <div>
    <label
      class="multiselect-label"
      :class="{ error: v$.multiselectValues.$error }"
    >
      <div class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </div>

      <multiselect
        ref="multiselection"
        :model-value="multiselectValues"
        track-by="value"
        label="label"
        selected-label
        multiple
        class="!border-none"
        hide-selected
        :placeholder="t(placeholder)"
        :options="options"
        :close-on-select="false"
        :clear-on-select="false"
        :select-label="t(label)"
        :deselect-label="t(deselectLabel)"
        :disabled="disabled"
        @update:model-value="updateValue"
        @close="v$.multiselectValues.$touch"
      >
        <!-- <div
          slot="beforeList"
          class="modal-mask z-[-1] cursor-pointer !bg-transparent"
          @click="closeMultiselect"
        /> -->
      </multiselect>

      <span v-if="v$.multiselectValues.$error" class="message">
        <template v-if="v$.multiselectValues.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </label>
  </div>
</template>

<style lang="scss" scope>
.multiselect {
  .multiselect__tags {
    @apply min-h-[2.5rem];
    z-index: 10;
  }
  .multiselect__content-wrapper {
    z-index: -1;
  }
}
</style>
