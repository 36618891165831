<template>
  <section class="flex flex-col w-full h-full overflow-hidden bg-n-background">
    <header class="sticky top-0 z-10 px-8">
      <div class="w-full max-w-4xl mx-auto">
        <div class="flex items-center justify-between w-full h-20 gap-2">
          <slot name="start" />
          <div class="flex gap-2 items-center">
            <slot name="end" />
          </div>
        </div>
      </div>
    </header>
    <main class="flex-1 px-8">
      <div class="w-full mx-auto max-w-4xl">
        <slot name="default" />
      </div>
    </main>
  </section>
</template>
