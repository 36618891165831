<script setup>
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStoreGetters } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';
import { useStore } from 'vuex';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';

const props = defineProps({
  selectedCampaign: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const store = useStore();
const getters = useStoreGetters();
const { t } = useI18n();

const dialogRef = ref(null);
const isOpen = ref(false);
const uiFlags = getters['wgptCampaigns/getUIFlags'];

const onClose = () => {
  isOpen.value = false;
  emit('close');
};

const open = () => {
  isOpen.value = true;
  nextTick(() => {
    dialogRef.value?.open();
  });
};

const close = () => {
  dialogRef.value.close();
  onClose();
};

const handleConfirm = async () => {
  try {
    await store.dispatch('wgptCampaigns/stop', props.selectedCampaign.id);
    useAlert(t('WGPT_CAMPAIGNS.EDITOR.API.STOP_SUCCESS'));
  } catch (error) {
    useAlert(t('WGPT_CAMPAIGNS.EDITOR.API.STOP_ERROR'));
  } finally {
    close();
  }
};

defineExpose({ open, close });
</script>

<template>
  <Dialog
    ref="dialogRef"
    :title="t('WGPT_CAMPAIGNS.STOP.TITLE')"
    :description="t('WGPT_CAMPAIGNS.STOP.DESCRIPTION')"
    :confirm-button-label="t('WGPT_CAMPAIGNS.STOP.CONFIRM')"
    :is-loading="uiFlags.isStopping"
    type="alert"
    @confirm="handleConfirm"
    @close="onClose"
  >
    <div class="border rounded-xl border-n-strong p-3 flex flex-col gap-3">
      <p class="mb-0 text-sm text-n-slate-11 whitespace-pre-wrap font-semibold">
        {{ t('WGPT_CAMPAIGNS.STOP.NOTE.TITLE') }}
      </p>
      <p class="mb-0 text-sm text-n-slate-11 whitespace-pre-wrap">
        {{ t('WGPT_CAMPAIGNS.STOP.NOTE.DESCRIPTION') }}
      </p>
    </div>
  </Dialog>
</template>
