<script setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';

import Input from 'next/input/Input.vue';
import CheckBox from 'next/wgpt/Form/CheckBox.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: 'text',
    validator: value => ['text', 'number', 'boolean'].includes(value),
  },
  label: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const inputValue = ref(props.modelValue.value);

const rules = {
  value: props.rules,
};

const v$ = useVuelidate(rules, { value: inputValue });

const inputLabel = computed(() => {
  return props.label + (props.required ? ' *' : '');
});

const inputMessage = computed(() => {
  if (v$.value.$error) {
    return t(
      'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.FORM.ERRORS.REQUIRED'
    );
  }
  return '';
});

// Watch for local input changes
watch(inputValue, async newValue => {
  await v$.value.$validate();
  emit('update:modelValue', {
    value: newValue,
    valid: !v$.value.$error,
  });
});

// Watch for external changes
watch(
  () => props.modelValue.value,
  newValue => {
    inputValue.value = newValue;
  }
);
</script>

<template>
  <div>
    <template v-if="type === 'boolean'">
      <label class="grid grid-cols-[auto_1fr] gap-2">
        <CheckBox v-model="inputValue" class="form-checkbox" />
        <span v-if="label" class="text-sm">
          {{ inputLabel }}
        </span>
      </label>
    </template>

    <template v-else>
      <label
        class="mb-0.5 text-sm font-medium text-n-slate-12 flex gap-1 items-center relative"
      >
        <span>{{ inputLabel }}</span>

        <div
          v-if="description"
          v-tooltip.right="{
            content: description,
            container: false,
          }"
          class="flex items-center justify-center flex-shrink-0 rounded-full hover:bg-n-alpha-2 size-4"
        >
          <Icon
            icon="i-lucide-info"
            class="flex-shrink-0 text-n-slate-11 size-3"
          />
        </div>
      </label>
      <Input
        v-model="inputValue"
        :type="type"
        :required="required"
        :message="inputMessage"
        :message-type="inputMessage ? 'error' : 'info'"
        class="form-input"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.required {
  @apply after:ml-0.5 after:text-red-500 after:content-['*'];
}
</style>
