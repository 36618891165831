<script setup>
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import CreateToolForm from './CreateToolForm.vue';

const props = defineProps({
  botId: {
    type: Number,
    required: true,
  },
  toolSet: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close']);
const { t } = useI18n();
const store = useStore();

const isOpen = ref(false);
const dialogRef = ref(null);
const formRef = ref(null);

const uiFlags = useMapGetter('wgptBotTools/getUIFlags');

const handleSubmit = async () => {
  if (!formRef.value) return;
  const { state, validate } = formRef.value;

  try {
    const isFormValid = await validate();
    if (!isFormValid) return;

    const tool = state;

    const createToolOptions = {
      botId: props.botId,
      toolSetId: props.toolSet.id,
      ...tool,
    };
    await store.dispatch('wgptBotTools/addTool', createToolOptions);

    const successMessage = t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.API.SUCCESS_MESSAGE'
    );
    useAlert(successMessage);
    dialogRef.value.close();
    emit('close');
  } catch (error) {
    const errorMessage =
      error?.message ||
      t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const handleOpen = () => {
  isOpen.value = true;
};

const handleDismiss = () => {
  isOpen.value = false;
  emit('close');
};

const dismiss = () => {
  dialogRef.value.close();
  handleDismiss();
};

const open = () => {
  handleOpen();
  nextTick(() => {
    dialogRef.value?.open();
  });
};

defineExpose({ open, dismiss });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="edit"
    :title="t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.TITLE')"
    :description="
      t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.DESCRIPTION', {
        name: toolSet.name,
      })
    "
    :is-loading="uiFlags.isUpdating"
    :disable-confirm-button="uiFlags.isUpdating"
    :confirm-button-label="t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.SAVE')"
    :cancel-button-label="
      t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.CANCEL')
    "
    overflow-y-auto
    @confirm="handleSubmit"
    @close="handleDismiss"
  >
    <CreateToolForm
      v-if="isOpen"
      ref="formRef"
      :tool-set="toolSet"
      @submit="handleSubmit"
    />
  </Dialog>
</template>
