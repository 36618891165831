<script setup>
import { ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const form = ref({
  name: props.modelValue.name || '',
  description: props.modelValue.description || '',
  // Add other fields as needed
});

watch(
  form,
  newValue => {
    emit('update:modelValue', { ...props.modelValue, ...newValue });
  },
  { deep: true }
);

const rules = {
  name: { required },
  description: {},
  // Add other field validations as needed
};

const v$ = useVuelidate(rules, form);

const onSubmit = () => {
  v$.value.$validate();
};
</script>

<template>
  <div class="px-3 pt-4 pb-16">
    <h5
      class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
    >
      <span class="truncate">{{
        $t('WGPT_CAMPAIGNS.EDITOR.SIDEBAR.SETTINGS.TITLE')
      }}</span>
    </h5>
    <p class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50">
      {{ $t('WGPT_CAMPAIGNS.EDITOR.SIDEBAR.SETTINGS.DESCRIPTION') }}
    </p>

    <form class="w-full" @submit.prevent="onSubmit">
      <div>
        <label :class="{ error: v$.name.$error }">
          {{ $t('WGPT_CAMPAIGNS.EDITOR.SIDEBAR.SETTINGS.FORM.NAME.LABEL') }}
          <input
            v-model.trim="form.name"
            type="text"
            :disabled="disabled"
            @input="v$.name.$touch"
            @blur="onSubmit"
          />
          <span v-if="v$.name.$error" class="message">
            {{ $t('WGPT_CAMPAIGNS.EDITOR.SIDEBAR.SETTINGS.FORM.NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="mt-4">
        <label :class="{ error: v$.description.$error }">
          {{
            $t('WGPT_CAMPAIGNS.EDITOR.SIDEBAR.SETTINGS.FORM.DESCRIPTION.LABEL')
          }}
          <textarea
            v-model.trim="form.description"
            :disabled="disabled"
            @input="v$.description.$touch"
            @blur="onSubmit"
          />
        </label>
      </div>
      <!-- Add other form fields as needed -->
    </form>
  </div>
</template>
