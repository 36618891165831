<script>
import { useAlert } from 'dashboard/composables';
import Sidebar from './components/sidebar/Index.vue';
import Trigger from './components/trigger/Index.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';

import {
  findStep,
  findStepAndDelete,
  findStepAndUpdate,
  getStepPath,
  getVariables,
  getFieldVariables,
} from './helpers/automationUtils';

export default {
  components: {
    Trigger,
    Sidebar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    disableTrigger: {
      type: Boolean,
      default: false,
    },
    disableTriggerAttributes: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      automation: {
        ...this.modelValue,
        name: this.modelValue.name,
        description: this.modelValue.description,
        steps: this.modelValue.steps,
        trigger: this.modelValue.trigger,
      },
      showActionsDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      actionsRegistry: 'wgptAutomationRegistry/getActions',
    }),
    automationId() {
      return this.modelValue.id;
    },
    trigger() {
      return this.automation.trigger || {};
    },
    triggerType() {
      return this.trigger.type;
    },
    triggerRegistry() {
      return this.$store.getters['wgptAutomationRegistry/getTrigger'](
        this.triggerType
      );
    },
    selectedStepId() {
      return this.$route.query.selected;
    },
    triggerVariables() {
      const trigger = this.triggerRegistry || {};
      const availableDataFields = trigger.available_data || {};
      const availableDataVariables = Object.entries(availableDataFields).map(
        ([name, field]) => {
          return { ...field, name };
        }
      );
      const inputMapping = this.trigger.input_mapping || {};
      const inputMappingFields = trigger.input_mapping_fields || [];
      const inputMappingVariables = getFieldVariables(
        inputMapping,
        inputMappingFields
      );
      return [...availableDataVariables, ...inputMappingVariables];
    },
    actionVariables() {
      const steps = this.automation.steps?.v || [];
      const actionsVariables = getVariables(
        this.selectedStepId,
        steps,
        this.actionsRegistry
      );
      return actionsVariables;
    },
    variables() {
      return [...this.triggerVariables, ...this.actionVariables];
    },
    disableTriggerChange() {
      if (this.disableTrigger) return true;
      if (!this.trigger.type) return false;
      const steps = this.automation.steps.v || [];
      return steps.length > 0;
    },
  },
  watch: {
    triggerType: {
      handler(value) {
        if (!value) return;
        this.$store.dispatch(
          'wgptAutomationRegistry/getActionsForTrigger',
          value
        );
      },
      immediate: true,
    },
    triggerRegistry: {
      handler(triggerRegistry) {
        const trigger = triggerRegistry || {};
        const availableDataFields = trigger.available_data || {};
        const variables = Object.keys(availableDataFields).map(name => {
          return { ...availableDataFields[name], name };
        });
        this.dispatchWrappers(variables ?? []);
      },
      immediate: true,
    },
    actionsRegistry: {
      handler(actionsRegistry) {
        const actions = actionsRegistry ?? [];
        const variables = [];
        actions.forEach(action => {
          const availableData = action.available_data ?? {};
          const actionVariables = Object.keys(availableData).map(name => {
            return { ...availableData[name], name };
          });
          variables.push(...actionVariables);
        });
        this.dispatchWrappers(variables ?? []);
      },
      immediate: true,
    },
    automation: {
      handler(automation) {
        this.$emit('update:modelValue', automation);
      },
      deep: true,
    },
    modelValue: {
      handler(modelValue, modelValueBefore) {
        if (!modelValue) return;
        if (JSON.stringify(modelValue) === JSON.stringify(modelValueBefore))
          return;

        this.automation = {
          ...modelValue,
          name: this.modelValue.name ?? modelValue.name,
          description: this.modelValue.description ?? modelValue.description,
          steps: {
            ...this.modelValue.steps,
            ...modelValue.steps,
          },
          trigger: {
            ...this.modelValue.trigger,
            ...modelValue.trigger,
          },
        };
      },
      immediate: true,
    },
  },
  validations: {
    automation: {},
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.$store.dispatch('wgptAutomationRegistry/getTriggers');
      this.$store.dispatch('wgptAutomationRegistry/getLogicalOperators');
      this.$store.dispatch('wgptAutomationRegistry/getComparisonOperators');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          throw new Error(
            this.$t('WGPT_AUTOMATIONS.EDITOR.API.INVALID_ERROR_MESSAGE')
          );
        }

        const id = this.automationId;
        const data = {
          name: this.automation.name,
          description: this.automation.description,
          trigger: this.cleanAttributes(
            this.automation.trigger,
            this.triggerRegistry
          ),
          steps: this.automation.steps,
        };
        await this.$store.dispatch('wgptAutomations/update', { id, ...data });
        useAlert(this.$t('WGPT_AUTOMATIONS.EDITOR.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    onTriggerChange(triggerUpdates) {
      const triggerBefore = this.automation.trigger || {};
      this.automation.trigger = {
        ...triggerBefore,
        ...triggerUpdates,
      };
    },
    onSettingsChange(automationUpdates) {
      Object.keys(automationUpdates).forEach(key => {
        this.automation[key] = automationUpdates[key];
      });
    },
    onStepUpdate(stepId, data) {
      try {
        const stepsBefore = this.automation.steps.v || [];
        const steps = stepId
          ? findStepAndUpdate(stepsBefore, stepId, data)
          : data;

        this.automation.steps = {
          v: steps,
        };
      } catch (error) {
        const errorMessage = error.message || this.$t('WGPT_AUTOMATIONS');
        useAlert(errorMessage);
      }
    },
    onStepDelete(stepId) {
      try {
        const stepsBefore = this.automation.steps.v || [];
        const selectedStepPath =
          getStepPath(stepsBefore, this.selectedStepId) || [];
        const steps = findStepAndDelete(stepsBefore, stepId);

        for (let i = selectedStepPath.length; i >= 0; i -= 1) {
          const id = selectedStepPath[i];
          if (findStep(steps, id)) {
            this.$router.replace({ query: { selected: id } }).catch(() => {});
            break;
          }
          if (i === 0) {
            this.$router.replace({ query: {} }).catch(() => {});
          }
        }

        this.automation.steps = {
          v: steps,
        };
      } catch (error) {
        const errorMessage = error.message || this.$t('WGPT_AUTOMATIONS');
        useAlert(errorMessage);
      }
    },

    dispatchWrappers(variables) {
      variables.forEach(variable => {
        if (variable.wrapper) {
          if (variable.custom_object) {
            this.$store.dispatch(
              'wgptAutomationRegistry/getCustomObjects',
              variable.custom_object
            );
            return;
          }
        }

        if (variable.model) {
          this.$store.dispatch(
            'wgptAutomationRegistry/getDynamicOptions',
            variable.model
          );
        }
      });
    },
  },
};
</script>

<template>
  <div class="flex">
    <div
      class="automation-rule-designer flex-1 h-full overflow-auto macro-gradient-radial dark:macro-dark-gradient-radial macro-gradient-radial-size"
    >
      <Trigger
        class="mb-[50vh] w-max min-w-full"
        :automation="automation"
        :variables="variables"
        :disabled="disabled"
        @step-change="onStepUpdate"
        @step-delete="onStepDelete"
      />
    </div>
    <Sidebar
      :variables="variables"
      :automation="automation"
      :disable-trigger-change="disableTriggerChange"
      :disable-trigger-attributes="disableTriggerAttributes"
      :disabled="disabled"
      @trigger-change="onTriggerChange"
      @step-change="onStepUpdate"
      @settings-change="onSettingsChange"
    >
      <template v-if="$slots.customSidebarSettings" #customSettings>
        <slot name="customSidebarSettings" />
      </template>
    </Sidebar>
  </div>
</template>

<style scoped>
.automation-rule-designer {
  --triggerThemeColor: #1f93ff;
  --actionThemeColor: #44ce4b;
  --branchThemeColor: #ffc532;
}

@tailwind components;
@layer components {
  .macro-gradient-radial {
    background-image: radial-gradient(#ebf0f5 1.2px, transparent 0);
  }
  .macro-dark-gradient-radial {
    background-image: radial-gradient(#293f51 1.2px, transparent 0);
  }
  .macro-gradient-radial-size {
    background-size: 1rem 1rem;
  }
}
</style>
