<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';
import { ref } from 'vue';

import CreateCampaignForm from './CreateCampaignForm.vue';

const emit = defineEmits(['close']);

const store = useStore();
const { t } = useI18n();

const campaignFormRef = ref(null);

const handleClose = () => emit('close');

const handleSubmit = async () => {
  try {
    if (!campaignFormRef.value) return;
    const { state, resetState } = campaignFormRef.value;
    const { name, description } = state;

    const data = {
      name,
      description,
    };
    await store.dispatch('wgptCampaigns/create', data);

    useAlert(t('WGPT_CAMPAIGNS.CREATE.FORM.API.SUCCESS_MESSAGE'));
    resetState();
    handleClose();
  } catch (error) {
    const errorMessage =
      error?.response?.message ||
      t('WGPT_CAMPAIGNS.CREATE.FORM.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};
</script>

<template>
  <div
    class="w-[400px] z-50 min-w-0 absolute top-10 ltr:right-0 rtl:left-0 bg-n-alpha-3 backdrop-blur-[100px] p-6 rounded-xl border border-slate-50 dark:border-slate-900 shadow-md flex flex-col gap-6"
  >
    <h3 class="text-base font-medium text-slate-900 dark:text-slate-50">
      {{ t(`WGPT_CAMPAIGNS.CREATE.TITLE`) }}
    </h3>
    <CreateCampaignForm
      ref="campaignFormRef"
      @cancel="handleClose"
      @submit="handleSubmit"
    />
  </div>
</template>
