<script setup>
import { nextTick, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore, useStoreGetters } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';
import { format } from 'date-fns';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import ExecuteCampaignForm from './ExecuteCampaignForm.vue';
import { getExecutionDetails } from '../../helpers/campaignHelper';

const props = defineProps({
  selectedCampaign: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close', 'executed']);
const getters = useStoreGetters();
const store = useStore();
const { t } = useI18n();

const dialogRef = ref(null);
const isOpen = ref(false);
const uiFlags = getters['wgptCampaigns/getUIFlags'];
const targetContactsCount = ref({});

const onClose = () => {
  isOpen.value = false;
  emit('close');
};

const isContactEmpty = computed(() => {
  return !targetContactsCount.value.total_contacts;
});

const formRef = ref(null);
const readonly = ref(false);

const open = (options = {}) => {
  readonly.value = options.readonly ?? false;
  targetContactsCount.value = options.targetContactsCount || {};
  isOpen.value = true;
  nextTick(() => {
    if (!dialogRef.value) return;

    dialogRef.value.open();

    if (props.selectedCampaign) {
      const { form, setForm } = formRef.value;
      const executionConfig = props.selectedCampaign.execution_config || {};
      const { start_date, timezone, start_time, end_time, contacts_per_hour } =
        executionConfig;

      setForm({
        start_date: start_date || form.start_date,
        timezone: timezone || form.timezone,
        start_time: start_time || form.start_time,
        end_time: end_time || form.end_time,
        contacts_per_hour: contacts_per_hour || form.contacts_per_hour,
      });
    }
  });
};

const close = () => {
  dialogRef.value.close();
  onClose();
};

const handleSubmit = async () => {
  try {
    if (!formRef.value) return;
    const { validate, form } = formRef.value;

    if (!(await validate())) return;

    if (props.selectedCampaign.status === 'draft') {
      const startOptions = {
        id: props.selectedCampaign.id,
        execution_config: {
          ...form,
          contacts_per_hour: form.contacts_per_hour || undefined,
        },
      };

      await store.dispatch('wgptCampaigns/start', startOptions);
    } else {
      const cancelOptions = props.selectedCampaign.id;
      await store.dispatch('wgptCampaigns/cancel', cancelOptions);
    }

    useAlert(t('WGPT_CAMPAIGNS.EDITOR.API.START_SUCCESS'));
    emit('executed');
    close();
  } catch (error) {
    const errorMessage =
      error?.response?.message || t('WGPT_CAMPAIGNS.EDITOR.API.START_ERROR');
    useAlert(errorMessage);
  }
};

const constructExecutionSummary = executionConfig => {
  const { total_contacts } = targetContactsCount.value;
  if (!total_contacts) {
    return t('WGPT_CAMPAIGNS.START.SUMMARY.NO_CONTACTS');
  }

  const executionDetails = getExecutionDetails(total_contacts, executionConfig);
  if (!executionDetails) {
    return t('WGPT_CAMPAIGNS.START.SUMMARY.UNAVAILABLE');
  }

  const startTime = new Date(`2000-01-01 ${executionConfig.start_time}`);
  const endTime = new Date(`2000-01-01 ${executionConfig.end_time}`);

  const { startAt, endAt, contactsPerHour, intervalSeconds, totalDays } =
    executionDetails;

  return t('WGPT_CAMPAIGNS.START.SUMMARY.CONTENT', {
    start_date: format(startAt, 'LLL d y'),
    start_time: format(startTime, 'h:mm a'),
    end_time: format(endTime, 'h:mm a'),
    contacts_per_hour: contactsPerHour,
    interval_seconds: intervalSeconds,
    total_contacts,
    total_days: totalDays,
    end_date: format(endAt, 'LLL d y, h:mm a'),
  });
};

const executionSummary = computed(() => {
  return constructExecutionSummary(formRef.value?.form);
});

defineExpose({ open, close });
</script>

<template>
  <Dialog
    ref="dialogRef"
    :title="t('WGPT_CAMPAIGNS.START.TITLE')"
    :confirm-button-label="t('WGPT_CAMPAIGNS.START.CONFIRM')"
    :cancel-button-label="t('WGPT_CAMPAIGNS.START.CLOSE')"
    :is-loading="uiFlags.isExecuting"
    :show-confirm-button="!readonly"
    :disable-confirm-button="isContactEmpty"
    overflow-y-auto
    @confirm="handleSubmit"
    @close="onClose"
  >
    <ExecuteCampaignForm
      v-if="isOpen"
      ref="formRef"
      :readonly="readonly"
      @submit="handleSubmit"
    />
    <div class="border rounded-xl border-n-strong p-3 flex flex-col gap-3">
      <p class="mb-0 text-sm text-n-slate-11 whitespace-pre-wrap font-semibold">
        {{ t('WGPT_CAMPAIGNS.START.SUMMARY.TITLE') }}
      </p>
      <p class="mb-0 text-sm text-n-slate-11 whitespace-pre-wrap">
        {{ executionSummary }}
      </p>
    </div>
  </Dialog>
</template>
