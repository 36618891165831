const extractGroupVariablePaths = (mappingFields, currentPath = []) => {
  const variablePath = {
    path: null,
    default: null,
    typePath: null,
    defaultType: null,
  };

  mappingFields.forEach(field => {
    if (field.is_variable_key) {
      variablePath.path = [...currentPath, field.name];
      variablePath.default = field.default;
    } else if (field.is_variable_type) {
      variablePath.typePath = [...currentPath, field.name];
      variablePath.defaultType = field.default;
    }
  });

  return variablePath;
};

const extractVariablePaths = (mappingFields, currentPath = []) => {
  const variablePaths = [];

  mappingFields.forEach(field => {
    if (field.type === 'group') {
      const nestedPath = currentPath;
      if (field.name) nestedPath.push(field.name);

      if (field.is_variable) {
        variablePaths.push(
          extractGroupVariablePaths(field.controls, nestedPath)
        );
      } else {
        variablePaths.push(extractVariablePaths(field.controls, nestedPath));
      }
      return;
    }

    if (field.type === 'array') {
      variablePaths.push(
        extractVariablePaths(field.controls, [...currentPath, field.name, 0])
      );
      return;
    }

    if (field.is_variable) {
      variablePaths.push({
        path: [...currentPath, field.name],
        params: field.variable_params,
      });
    }
  });

  return variablePaths;
};

const flattenPaths = variablePaths => {
  return variablePaths.flatMap(item => {
    return Array.isArray(item) ? flattenPaths(item) : item;
  });
};

const extractPathValue = (data, path, defaultValue) => {
  const [firstPath, ...remainingPath] = path;

  if (remainingPath.length === 0) {
    return data[firstPath] ?? defaultValue;
  }

  if (typeof firstPath === 'number') {
    const arrayData = data ?? [];
    return arrayData.map(item =>
      extractPathValue(item, remainingPath, defaultValue)
    );
  }

  return extractPathValue(data[firstPath], remainingPath, defaultValue);
};

const extractValuesFromData = (data, variablePaths) => {
  const flattenedPaths = flattenPaths(variablePaths);
  const extractedData = [];

  flattenedPaths.forEach(entry => {
    const names = extractPathValue(data, entry.path, entry.default);
    const types = entry.typePath
      ? extractPathValue(data, entry.typePath, entry.defaultType)
      : null;

    if (Array.isArray(names)) {
      names.forEach((name, idx) => {
        let type = Array.isArray(types) ? types[idx] : types;
        if (type === 'string') type = 'text';
        if (type === 'integer' || type === 'float') type = 'number';
        extractedData.push({ name, type, ...entry.params });
      });
    } else {
      extractedData.push({ name: names, type: types, ...entry.params });
    }
  });

  return extractedData;
};

export const getMappingFieldVariables = (mapping, mappingFields = []) => {
  const variablePaths = extractVariablePaths(mappingFields);
  return extractValuesFromData(mapping, variablePaths);
};
