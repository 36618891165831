<script setup>
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';
import { useStoreGetters } from 'dashboard/composables/store';
import { helpers, required } from '@vuelidate/validators';
import { alphanumericUnderscore } from '../helpers/rulesHelper';
import useVuelidate from '@vuelidate/core';

import Spinner from 'next/spinner/Spinner.vue';
import Button from 'next/button/Button.vue';
import Breadcrumb from 'next/breadcrumb/Breadcrumb.vue';
import Editor from 'next/wgpt/Automation/editor/Index.vue';
import ToolEditorLayout from './ToolEditorLayout.vue';
import ToolEditorSettings from './ToolEditorSettings.vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  bot: {
    type: Object,
    required: true,
  },
  toolSet: {
    type: Object,
    required: true,
  },
  toolId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['back']);

const { t } = useI18n();
const getters = useStoreGetters();
const store = useStore();
const route = useRoute();

const tools = computed(() => {
  return getters['wgptBotTools/getTools'].value(props.toolSet.id);
});
const tool = computed(() => {
  return getters['wgptBotTools/getTool'].value(props.toolSet.id, props.toolId);
});
const uiFlags = useMapGetter('wgptBotTools/getUIFlags');

const isUniqueName = value => {
  if (!value) return true;
  return !tools.value.some(
    ({ name: toolName, id: toolId }) =>
      toolName === value && toolId !== props.toolId
  );
};

const initialState = {};
const state = reactive({ ...initialState });
const rules = {
  value: {
    name: {
      required,
      alphanumericUnderscore,
      isUnique: helpers.withMessage(
        () => t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.FORM.NAME.ERROR_UNIQUE'),
        isUniqueName
      ),
    },
    description: {
      required,
    },
  },
};
const v$ = useVuelidate(rules, state);

const hasPendingChanges = computed(() => {
  if (!tool.value) return false;

  return (
    state.value.name !== tool.value.name ||
    state.value.description !== tool.value.description ||
    JSON.stringify(state.value.steps) !== JSON.stringify(tool.value.steps) ||
    JSON.stringify(state.value.trigger) !== JSON.stringify(tool.value.trigger)
  );
});

const isLoading = computed(() => {
  return false;
});

const breadcrumbItems = computed(() => {
  const items = [
    {
      label: t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.BREADCRUMB.TOOLS'),
      link: '#',
    },
  ];
  if (tool.value.name) {
    items.push({
      label: tool.value.name,
    });
  }
  return items;
});

const selected = computed(() => {
  return route.query.selected;
});

const settingsHasError = computed(() => {
  return v$.value.$errors.some(error =>
    ['name', 'description'].includes(error.$property)
  );
});

const handleSaveTool = async () => {
  try {
    if (v$.value.$invalid) {
      v$.value.$touch();
      throw new Error(t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.API.ERROR_MESSAGE'));
    }

    const updateData = {
      botId: props.bot.id,
      toolSetId: props.toolSet.id,
      id: props.toolId,
      name: state.value.name,
      description: state.value.description,
      trigger: state.value.trigger,
      steps: state.value.steps,
    };
    await store.dispatch('wgptBotTools/updateTool', updateData);
    useAlert(t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.API.SUCCESS_MESSAGE'));
  } catch (error) {
    useAlert(
      error?.message || t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.API.ERROR_MESSAGE')
    );
  }
};

watch(
  tool,
  (newTool, oldTool) => {
    if (!oldTool?.value) {
      state.value = {
        name: newTool.name,
        description: newTool.description,
        trigger: newTool.trigger,
        steps: newTool.steps,
      };
    }
  },
  { immediate: true }
);

watch(selected, () => {
  v$.value.$touch();
});

const goToBotToolsList = () => {
  emit('back');
};
</script>

<template>
  <ToolEditorLayout>
    <template #start>
      <div class="flex items-center gap-5">
        <Breadcrumb :items="breadcrumbItems" @click="goToBotToolsList" />
      </div>
    </template>
    <template #end>
      <Button
        :label="t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.HEADER.SAVE_BUTTON')"
        :is-loading="uiFlags.isUpdating"
        :disabled="!hasPendingChanges || uiFlags.isUpdating"
        size="sm"
        class="group-hover/campaign-button:brightness-110"
        @click="handleSaveTool"
      />

      <router-link
        :to="{ query: { selected: 'settings' } }"
        :class="{
          'settings-button-active': selected === 'settings',
          'settings-button-invalid': settingsHasError,
        }"
        replace
      >
        <Button
          variant="ghost"
          :color="settingsHasError ? 'ruby' : 'slate'"
          icon="i-lucide-bolt"
          size="sm"
          class="settings-button"
          :title="t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.HEADER.SETTINGS_BUTTON')"
        />
      </router-link>
    </template>
    <div
      v-if="isLoading"
      class="flex items-center justify-center py-10 text-n-slate-11"
    >
      <Spinner />
    </div>
    <Editor
      v-else
      v-model="state.value"
      class="h-full border-t border-n-weak"
      disable-trigger
    >
      <template #customSidebarSettings>
        <ToolEditorSettings
          v-model="state.value"
          :tool-set="toolSet"
          :tool="tool"
        />
      </template>
    </Editor>
  </ToolEditorLayout>
</template>

<style lang="scss" scoped>
.settings-button-active .settings-button {
  @apply outline-none outline-n-brand outline-offset-1;
}
.settings-button-invalid .settings-button {
  @apply outline-none outline-n-ruby-10 outline-offset-1;
}
</style>
