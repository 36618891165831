import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import {
  validateField,
  skipValidation,
} from '../../../helpers/fieldValidation';
import { getVariables } from '../../../helpers/stepUtils';

function validateActionStepFormFields(step, triggerRegistry) {
  const triggerAttributes = step.attributes || {};
  const formFields = triggerRegistry.form_fields || [];
  formFields.forEach(field => {
    const fieldName = field.name;
    const triggerAttributeValue = triggerAttributes[fieldName] ?? field.default;
    if (skipValidation(field, triggerAttributes)) return;
    validateField(field, triggerAttributeValue);
  });
}

function validateActionStepOutputMappingFields(step, triggerRegistry) {
  const triggerOutputMapping = step.input_mapping || {};
  const inputMappingFields = triggerRegistry.input_mapping_fields || [];
  inputMappingFields.forEach(field => {
    const fieldName = field.name;
    const triggerOutputMappingValue =
      triggerOutputMapping[fieldName] ?? field.default;
    validateField(field, triggerOutputMappingValue);
  });
}

const triggerValidation = triggerRegistry =>
  helpers.withParams(
    {
      value: triggerRegistry,
    },
    step => {
      try {
        validateActionStepFormFields(step, triggerRegistry);
        validateActionStepOutputMappingFields(step, triggerRegistry);
        return true;
      } catch (error) {
        return false;
      }
    }
  );

const variablesValidation = params =>
  helpers.withParams(
    {
      value: params,
    },
    step => {
      try {
        const [triggerRegistry, inheritedVariables] = params;
        const inputMappingFields = triggerRegistry?.input_mapping_fields ?? [];
        const inputMapping = step.input_mapping ?? {};
        const stepVariables = getVariables(inputMapping, inputMappingFields);
        const invalidVariables = stepVariables.filter(stepVariableName => {
          if (!stepVariableName) return false;
          if (!/^[a-zA-Z0-9_]+$/.test(stepVariableName)) return true;
          return false;
        });
        if (invalidVariables.length > 0) {
          throw new Error(
            `Variables ${invalidVariables.join(
              ', '
            )} must not contain special characters.`
          );
        }
        const duplicateVariables = stepVariables.filter(stepVariableName => {
          const matched = inheritedVariables.filter(inheritedVariable => {
            if (inheritedVariables.wrapper) return false;
            return inheritedVariable.name === stepVariableName;
          });
          return matched.length > 1;
        });
        if (duplicateVariables.length > 0) {
          throw new Error(
            `Variables ${duplicateVariables.join(', ')} cannot be used anymore.`
          );
        }
        return true;
      } catch (error) {
        return false;
      }
    }
  );
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      trigger: {
        triggerValidation: triggerValidation(this.triggerRegistry),
        variablesValidation: variablesValidation([
          this.triggerRegistry,
          this.variables,
        ]),
      },
    };
  },
};
