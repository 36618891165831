<script setup>
import { computed, watch, reactive, ref, nextTick, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useStoreGetters, useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';
import { OnClickOutside } from '@vueuse/components';
import { zonedTimeToUtc } from 'date-fns-tz';
import useVuelidate from '@vuelidate/core';

import Spinner from 'next/spinner/Spinner.vue';
import Button from 'next/button/Button.vue';
import DropdownMenu from 'next/dropdown-menu/DropdownMenu.vue';
import Breadcrumb from 'next/breadcrumb/Breadcrumb.vue';
import Editor from 'next/wgpt/Automation/editor/Index.vue';
import CampaignEditorLayout from './CampaignEditorLayout.vue';
import CampaignEditorSettings from './CampaignEditorSettings.vue';
import ExecuteCampaignDialog from './execute/ExecuteCampaignDialog.vue';
import StopCampaignDialog from '../stop/StopCampaignDialog.vue';
import CampaignStatusLabel from '../CampaignStatusLabel.vue';
import { getExecutionDetails } from '../helpers/campaignHelper';

const { t } = useI18n();
const store = useStore();
const getters = useStoreGetters();
const route = useRoute();
const router = useRouter();
const campaignId = computed(() => route.params.campaignId);
const initialState = {};
const state = reactive({ ...initialState });
const v$ = useVuelidate({}, state);

const showExecuteActionMenu = ref(false);
const executeDialogRef = ref(null);
const stopDialogRef = ref(null);
const now = ref(new Date());
const timer = setInterval(() => {
  now.value = new Date();
}, 1000);

onUnmounted(() => {
  clearInterval(timer);
});

const uiFlags = useMapGetter('wgptCampaigns/getUIFlags');

const campaign = computed(() => {
  return getters['wgptCampaigns/getCampaign'].value(campaignId.value);
});

const hasPendingChanges = computed(() => {
  if (!campaign.value) return false;

  return (
    state.value?.name !== campaign.value.name ||
    state.value?.description !== campaign.value.description ||
    JSON.stringify(state.value?.steps) !==
      JSON.stringify(campaign.value.steps) ||
    JSON.stringify(state.value?.trigger) !==
      JSON.stringify(campaign.value.trigger)
  );
});

const isLoading = computed(() => {
  if (campaign.value === campaignId.value) return false;
  return uiFlags.value.isFetchingItem;
});

const executionDetails = computed(() => {
  const executionConfig = campaign.value.execution_config || {};
  return getExecutionDetails(
    executionConfig.estimated_total_contacts || 0,
    executionConfig
  );
});

const isScheduled = computed(() => {
  return campaign.value.status === 'scheduled';
});

const isCancelled = computed(() => {
  return campaign.value.status === 'cancelled';
});

const isCompleted = computed(() => {
  const { status, execution_config } = campaign.value;
  if (status !== 'scheduled') return false;

  const executionConfig = execution_config || {};
  const { timezone } = executionConfig;

  if (!executionDetails.value) return false;

  const { endAt } = executionDetails.value;
  const lastSchedule = zonedTimeToUtc(endAt, timezone);

  return now.value > lastSchedule;
});

const executeMenuItems = computed(() => {
  return [
    ...(isScheduled.value || isCancelled.value
      ? [
          {
            label: t('WGPT_CAMPAIGNS.EDITOR.HEADER.VIEW'),
            value: 'view',
            action: 'view-execution',
          },
        ]
      : []),
    {
      label: t('WGPT_CAMPAIGNS.EDITOR.HEADER.STOP'),
      value: 'cancel',
      action: 'cancel-execution',
      disabled: !isScheduled.value || isCompleted.value,
    },
  ];
});

const breadcrumbItems = computed(() => {
  const items = [
    {
      label: t('WGPT_CAMPAIGNS.EDITOR.HEADER.BREADCRUMB.CAMPAIGNS'),
      link: '#',
    },
  ];
  if (campaign.value.name) {
    items.push({
      label: campaign.value.name,
    });
  }
  return items;
});

const isExecuteDisabled = computed(() => {
  if (
    !campaign.value ||
    !campaignId.value ||
    uiFlags.value.isFetchingTargetDetails ||
    hasPendingChanges.value
  ) {
    return true;
  }
  return campaign.value.status !== 'draft';
});

watch(
  campaignId,
  (newCampaignId, oldCampaignId) => {
    if (!newCampaignId) return;
    if (newCampaignId === oldCampaignId) return;

    store.dispatch('wgptCampaigns/show', newCampaignId);
  },
  { immediate: true }
);

watch(
  campaign,
  (newCampaign, oldCampaign) => {
    if (!oldCampaign?.value) {
      state.value = {
        name: newCampaign.name,
        description: newCampaign.description,
        trigger: newCampaign.trigger,
        steps: newCampaign.steps,
      };
    }
  },
  { immediate: true }
);

const handleSaveCampaign = async () => {
  try {
    if (v$.value.$invalid) {
      v$.value.$touch();
      throw new Error(t('WGPT_CAMPAIGNS.EDITOR.API.ERROR_MESSAGE'));
    }

    const updateData = {
      id: campaignId.value,
      description: state.value.description,
      name: state.value.name,
      wgpt_automation_attributes: {
        trigger: state.value.trigger,
        steps: state.value.steps,
      },
    };
    await store.dispatch('wgptCampaigns/update', updateData);
    useAlert(t('WGPT_CAMPAIGNS.EDITOR.API.SUCCESS_MESSAGE'));
  } catch (error) {
    useAlert(error?.message || t('WGPT_CAMPAIGNS.EDITOR.API.ERROR_MESSAGE'));
  }
};

const goToCampaignsList = () => {
  if (window.history.state?.back || window.history.length > 1) {
    router.back();
  } else {
    router.push(`/app/accounts/${route.params.accountId}/contacts?page=1`);
  }
};

const handleExecute = async () => {
  if (campaign.value?.is_executed) return;

  try {
    await store.dispatch(
      'wgptCampaigns/getTargetContactsCount',
      campaignId.value
    );
    nextTick(() => {
      const options = {
        targetContactsCount:
          getters['wgptCampaigns/getTargetContactsCount'].value,
      };
      executeDialogRef.value?.open(options);
    });
  } catch (error) {
    useAlert(t('WGPT_CAMPAIGNS.EDITOR.API.FETCH_TARGET_CONTACTS_ERROR'));
  }
};

const handleCancelExecution = async () => {
  showExecuteActionMenu.value = false;
  stopDialogRef.value?.open();
};

const handleExecuteAction = ({ action }) => {
  if (action === 'cancel-execution') {
    handleCancelExecution();
  }
  if (action === 'view-execution') {
    const options = {
      targetContactsCount: {
        total_contacts:
          campaign.value.execution_config?.estimated_total_contacts ?? 0,
      },
      readonly: true,
    };
    executeDialogRef.value?.open(options);
  }
};
</script>

<template>
  <CampaignEditorLayout>
    <template #start>
      <div class="flex items-center gap-5">
        <Breadcrumb :items="breadcrumbItems" @click="goToCampaignsList" />
        <CampaignStatusLabel :campaign="campaign" :now="now" />
      </div>
    </template>
    <template #end>
      <Button
        :label="t('WGPT_CAMPAIGNS.EDITOR.HEADER.SAVE_BUTTON')"
        :is-loading="uiFlags.isUpdating"
        :disabled="!hasPendingChanges || uiFlags.isUpdating || isCancelled"
        size="sm"
        class="group-hover/campaign-button:brightness-110"
        @click="handleSaveCampaign"
      />

      <div class="flex items-center">
        <Button
          :label="t('WGPT_CAMPAIGNS.EDITOR.HEADER.START')"
          size="sm"
          class="ltr:rounded-r-none rtl:rounded-l-none"
          :is-loading="uiFlags.isFetchingTargetDetails"
          :disabled="isExecuteDisabled"
          @click="handleExecute"
        />
        <div class="relative">
          <OnClickOutside @trigger="showExecuteActionMenu = false">
            <Button
              icon="i-lucide-chevron-down"
              size="sm"
              :disabled="!campaignId"
              class="ltr:rounded-l-none rtl:rounded-r-none"
              @click.stop="showExecuteActionMenu = !showExecuteActionMenu"
            />
            <DropdownMenu
              v-if="showExecuteActionMenu"
              :menu-items="executeMenuItems"
              class="mt-2 ltr:right-0 rtl:left-0 top-full"
              @action="handleExecuteAction($event)"
            />
          </OnClickOutside>
        </div>
      </div>

      <router-link
        :to="{ query: { selected: 'settings' } }"
        :class="{
          'settings-button-active': $route.query.selected === 'settings',
        }"
        replace
      >
        <Button
          variant="ghost"
          color="slate"
          icon="i-lucide-bolt"
          size="sm"
          class="settings-button"
        />
      </router-link>
    </template>
    <div
      v-if="isLoading"
      class="flex items-center justify-center py-10 text-n-slate-11"
    >
      <Spinner />
    </div>
    <Editor
      v-else
      v-model="state.value"
      class="h-full border-t border-n-weak"
      :disable-trigger-attributes="isScheduled || isCancelled"
      :disabled="isCancelled"
      disable-trigger
    >
      <template #customSidebarSettings>
        <CampaignEditorSettings v-model="state.value" :disabled="isCancelled" />
      </template>
    </Editor>
    <ExecuteCampaignDialog
      ref="executeDialogRef"
      :selected-campaign="campaign"
    />
    <StopCampaignDialog ref="stopDialogRef" :selected-campaign="campaign" />
  </CampaignEditorLayout>
</template>

<style lang="scss" scoped>
.settings-button-active .settings-button {
  @apply outline-none;
  outline-color: #1f93ff;
  outline-offset: 1px;
}
</style>
