<script>
import Dropdown from '../../../components/Dropdown.vue';
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';

export default {
  components: {
    Dropdown,
    WootDropdownItem,
    WootDropdownMenu,
  },
  mixins: [translateMixins],
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    event: {
      type: [Object, Event],
      default: () => {},
    },
    variables: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: '',
    },
    filterByPrefix: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'add'],
  data() {
    return {
      search: '',
      breadcrumbs: [],
    };
  },
  computed: {
    variableItems() {
      const items = this.variables;
      return items.map(item => {
        const dropdownLabel = this.t(item.label, item.name);
        const dropdownValue = item.name;
        const children = this.getChildItems(item);
        const prefix = item.prefix;
        return { ...item, dropdownLabel, dropdownValue, children, prefix };
      });
    },
    wrapperItems() {
      const breadcrumbs = this.breadcrumbs;
      const breadcrumb = breadcrumbs[breadcrumbs.length - 1];
      if (breadcrumb) return this.getChildItems(breadcrumb);

      return [];
    },
    dropdownItems() {
      return this.breadcrumbs.length ? this.wrapperItems : this.variableItems;
    },
    preFilteredDropdownItems() {
      return this.dropdownItems.filter(item => {
        if (!this.filterByPrefix) return true;
        const prefix = item.prefix ?? '%';
        return prefix === this.filterByPrefix;
      });
    },
    filteredDropdownItems() {
      const searchQuery = this.search.toLowerCase();
      return this.preFilteredDropdownItems
        .filter(item => {
          const searchIndices = [
            item.dropdownLabel,
            ...item.children.map(({ dropdownLabel }) => dropdownLabel),
          ]
            .join()
            .toLowerCase();
          return searchIndices.includes(searchQuery);
        })
        .filter((item, index, items) => {
          return (
            index ===
            items.findIndex(i => i.dropdownValue === item.dropdownValue)
          );
        })
        .map(item => {
          const active = item.dropdownValue === this.selected;
          return { ...item, active };
        });
    },
    actionLevelVariables() {
      return this.filteredDropdownItems.filter(item => item.prefix === '#');
    },
    normalVariables() {
      return this.filteredDropdownItems.filter(
        item => !item.prefix || item.prefix === '%'
      );
    },
    hasActionLevelVariables() {
      return this.actionLevelVariables.length > 0;
    },
    hasNormalVariables() {
      return this.normalVariables.length > 0;
    },
    noItems() {
      return this.showDropdown && this.preFilteredDropdownItems.length === 0;
    },
    noResult() {
      return this.filteredDropdownItems.length === 0 && this.search !== '';
    },
  },
  watch: {
    showDropdown(current, before) {
      if (current !== before) {
        if (current) {
          this.focusInput();
          this.onDropdownShow();
        } else {
          this.onDropdownClose();
        }
      }
    },
    noItems: {
      handler(value) {
        if (value) {
          this.$emit('close');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$nextTick(() => {
        if (!this.$refs.searchbar) return;
        this.$refs.searchbar.focus();
      });
    },
    onDropdownShow() {
      this.setInitialPage();
    },
    onDropdownClose() {
      this.setDefaults();
    },
    setDefaults() {
      this.breadcrumbs = [];
      this.search = '';
    },
    setInitialPage() {
      const breadcrumbsPath = this.selected.split('.').slice(0, -1);
      if (breadcrumbsPath.length === 0) return;
      this.breadcrumbs = breadcrumbsPath
        .map(name => {
          return this.variables.find(item => item.name === name);
        })
        .filter(Boolean);
    },
    getChildItems(variable) {
      if (variable.wrapper) {
        const breadcrumbs = this.breadcrumbs;

        if (variable.custom_object) {
          const customObjects =
            this.$store.getters['wgptAutomationRegistry/getCustomObjects'](
              variable.custom_object
            ) || {};
          const items = customObjects.options || [];
          return items.map(item => {
            const dropdownLabel = this.t(item.label, item.name);
            const dropdownValue = [
              breadcrumbs.map(({ name }) => name),
              item.name,
            ].join('.');
            const children = this.getChildItems(item);
            const prefix = variable.prefix;
            return { ...item, dropdownLabel, dropdownValue, children, prefix };
          });
        }
        if (variable.model) {
          const dynamicOptions =
            this.$store.getters['wgptAutomationRegistry/getDynamicOptions'](
              variable.model
            ) || {};
          const items = dynamicOptions.options || [];
          return items.map(item => {
            const dropdownLabel = item.label;
            const dropdownValue = [
              breadcrumbs.map(({ name }) => name),
              item.value,
            ].join('.');
            const children = this.getChildItems(item);
            const prefix = variable.prefix;
            return { ...item, dropdownLabel, dropdownValue, children, prefix };
          });
        }
      }

      return [];
    },
    onClick(variable) {
      if (variable.wrapper) {
        this.breadcrumbs.push(variable);
        return;
      }

      this.$emit('add', variable.dropdownValue, variable.prefix);
    },
    clearBreadcrumbs() {
      this.breadcrumbs = [];
    },
    selectBreadcrumb({ name: breadcrumbName }) {
      const breadcrumbIndex = this.breadcrumbs.findIndex(
        ({ name }) => name === breadcrumbName
      );
      this.breadcrumbs = this.breadcrumbs.slice(0, breadcrumbIndex + 1);
    },
  },
};
</script>

<template>
  <Dropdown
    class="variables-dropdown pointer-events-auto"
    :show-dropdown="showDropdown"
    :event="event"
    anchor="bottom"
    :gap="4"
    @close="$emit('close')"
  >
    <div class="w-[300px]">
      <div v-if="showSearch" class="p-2">
        <input
          ref="searchbar"
          v-model="search"
          type="text"
          class="search-input !m-0 !h-8"
          :placeholder="
            $t(
              'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.VARIABLES_DROPDOWN.MULTI_SELECTOR.PLACEHOLDER'
            )
          "
        />
      </div>

      <div class="p-2 py-1 overflow-auto max-h-[10rem]">
        <template v-if="hasActionLevelVariables">
          <div class="flex items-center gap-0.5">
            <woot-button
              variant="link"
              size="small"
              color-scheme="secondary"
              @click="clearBreadcrumbs"
            >
              {{
                $t(
                  'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.VARIABLES_DROPDOWN.BREADCRUMBS.ACTION_LEVEL_VARIABLES'
                )
              }}
            </woot-button>
            <div
              v-for="(breadcrumb, index) in breadcrumbs"
              :key="index"
              class="breadcrumb overflow-hidden"
            >
              <fluent-icon icon="chevron-right" class="shrink-0" size="14" />
              <woot-button
                variant="link"
                size="small"
                color-scheme="secondary"
                class-names="truncate"
                @click="selectBreadcrumb(breadcrumb)"
              >
                {{ t(breadcrumb.label, breadcrumb.name) }}
              </woot-button>
            </div>
          </div>
          <WootDropdownMenu class="mt-2">
            <WootDropdownItem
              v-for="item of actionLevelVariables"
              :key="item.dropdownValue"
            >
              <woot-button
                class="multiselect-dropdown--item"
                :variant="item.active ? 'flat' : 'clear'"
                :color-scheme="item.active ? 'primary' : 'secondary'"
                class-names="pe-2"
                @click="onClick(item)"
              >
                <div class="flex items-center justify-between">
                  <span
                    class="my-0 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
                    :title="item.dropdownLabel"
                  >
                    {{ item.dropdownLabel }}
                  </span>
                  <fluent-icon
                    v-if="item.wrapper"
                    class="shrink-0"
                    icon="chevron-right"
                    size="14"
                  />
                </div>
              </woot-button>
            </WootDropdownItem>
          </WootDropdownMenu>
        </template>
        <template v-if="hasNormalVariables">
          <div class="flex items-center gap-0.5">
            <woot-button
              variant="link"
              size="small"
              color-scheme="secondary"
              @click="clearBreadcrumbs"
            >
              {{
                $t(
                  'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.VARIABLES_DROPDOWN.BREADCRUMBS.VARIABLES'
                )
              }}
            </woot-button>
            <div
              v-for="(breadcrumb, index) in breadcrumbs"
              :key="index"
              class="breadcrumb overflow-hidden"
            >
              <fluent-icon icon="chevron-right" class="shrink-0" size="14" />
              <woot-button
                variant="link"
                size="small"
                color-scheme="secondary"
                class-names="truncate"
                @click="selectBreadcrumb(breadcrumb)"
              >
                {{ t(breadcrumb.label, breadcrumb.name) }}
              </woot-button>
            </div>
          </div>
          <WootDropdownMenu class="mt-2">
            <WootDropdownItem
              v-for="item of normalVariables"
              :key="item.dropdownValue"
            >
              <woot-button
                class="multiselect-dropdown--item"
                :variant="item.active ? 'flat' : 'clear'"
                :color-scheme="item.active ? 'primary' : 'secondary'"
                class-names="pe-2"
                @click="onClick(item)"
              >
                <div class="flex items-center justify-between">
                  <span
                    class="my-0 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
                    :title="item.dropdownLabel"
                  >
                    {{ item.dropdownLabel }}
                  </span>
                  <fluent-icon
                    v-if="item.wrapper"
                    class="shrink-0 rtl:rotate-180"
                    icon="chevron-right"
                    size="14"
                  />
                </div>
              </woot-button>
            </WootDropdownItem>
          </WootDropdownMenu>
        </template>
        <h4
          v-if="noResult"
          class="w-full justify-center items-center flex text-slate-500 dark:text-slate-300 py-2 px-2.5 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
        >
          {{
            $t(
              'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.VARIABLES_DROPDOWN.MULTI_SELECTOR.NO_RESULTS'
            )
          }}
        </h4>
      </div>
    </div>
  </Dropdown>
</template>

<style lang="scss" scoped>
.breadcrumb {
  @apply flex items-center gap-0.5 text-slate-500 dark:text-slate-500;
}
</style>
