<script setup>
import { reactive, watch, computed } from 'vue';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useStoreGetters } from 'dashboard/composables/store';

import Input from 'next/input/Input.vue';
import TextAreaNext from 'next/textarea/TextArea.vue';

const props = defineProps({
  toolSet: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const getters = useStoreGetters();

const alphanumericUnderscore = helpers.regex(/^[a-z0-9_]*$/);

const tools = computed(() => {
  return getters['wgptBotTools/getTools'].value(props.toolSet.id);
});

const isUniqueName = value => {
  if (!value) return true;
  return !tools.value.some(tool => tool.name === value);
};

const rules = {
  name: {
    required,
    alphanumericUnderscore,
    maxLength: maxLength(30),
    isUnique: helpers.withMessage(
      () => t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.NAME.ERROR_UNIQUE'),
      isUniqueName
    ),
  },
  description: { required },
};

const state = reactive({
  name: '',
  description: '',
});

const v$ = useVuelidate(rules, state);

const errorMessage = computed(() => {
  if (!v$.value.name.$error) return '';
  if (v$.value.name.alphanumericUnderscore.$invalid) {
    return t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.NAME.ERROR_ALPHANUMERIC'
    );
  }
  if (v$.value.name.maxLength.$invalid) {
    return t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.NAME.ERROR_MAX_LENGTH'
    );
  }
  if (v$.value.name.isUnique.$invalid) {
    return t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.NAME.ERROR_UNIQUE');
  }
  return t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.NAME.ERROR');
});

const descriptionErrorMessage = computed(() => {
  if (!v$.value.description.$error) return '';
  return t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.DESCRIPTION.ERROR');
});

watch(
  () => state.name,
  newValue => {
    state.name = newValue.toLowerCase().replace(/\s+/g, '_');
    v$.value.name.$touch();
  }
);

const validate = () => {
  return v$.value.$validate();
};

defineExpose({
  state,
  validate,
});
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="validate">
    <label>
      {{ $t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.NAME.LABEL') }}
      <Input
        v-model.trim="state.name"
        type="text"
        data-testid="tool-name"
        :message="errorMessage"
        :message-type="v$.name.$error ? 'error' : 'info'"
      />
    </label>

    <label>
      {{ $t('WGPT_BOTS.EDIT.TABS.TOOLS.CREATE_TOOL.FORM.DESCRIPTION.LABEL') }}
      <TextAreaNext
        v-model.trim="state.description"
        custom-text-area-class="!outline !outline-0"
        data-testid="tool-description"
        rows="3"
        :message="descriptionErrorMessage"
        :message-type="v$.description.$error ? 'error' : 'info'"
      />
    </label>
  </form>
</template>
