const OPERATING_SECONDS_PER_HOUR = 3600;
const MIN_CONTACTS_PER_HOUR = 6;

export const getExecutionDetails = (numberOfContacts, executionConfig = {}) => {
  const { start_date, start_time, end_time, contacts_per_hour } =
    executionConfig;

  if (!start_date || !start_time || !end_time || !numberOfContacts)
    return undefined;

  const baseStartTime = new Date(`${start_date} ${start_time}`);
  const baseEndTime = new Date(`${start_date} ${end_time}`);
  if (baseStartTime >= baseEndTime) return undefined;

  const operatingSecondsPerDay = (baseEndTime - baseStartTime) / 1000;
  if (operatingSecondsPerDay < OPERATING_SECONDS_PER_HOUR) return undefined;

  const contactsPerHour = contacts_per_hour || MIN_CONTACTS_PER_HOUR;
  const delayBetweenContactsInSeconds = Math.ceil(
    OPERATING_SECONDS_PER_HOUR / contactsPerHour
  );
  const contactsPerDay =
    Math.floor(operatingSecondsPerDay / delayBetweenContactsInSeconds) || 1;
  const totalDays = Math.ceil(numberOfContacts / contactsPerDay);

  const startTime = new Date(baseStartTime);
  const lastDayContacts = numberOfContacts % contactsPerDay || contactsPerDay;

  const secondsNeededLastDay =
    (lastDayContacts - 1) * delayBetweenContactsInSeconds;
  const endTime = new Date(baseStartTime);
  endTime.setDate(endTime.getDate() + totalDays - 1);
  endTime.setSeconds(endTime.getSeconds() + secondsNeededLastDay);

  return {
    startAt: startTime,
    endAt: endTime,
    contactsPerHour,
    intervalSeconds: delayBetweenContactsInSeconds,
    totalDays,
  };
};
