<script>
import AddStepButton from '../../addStepDropdown/AddStepButton.vue';
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';

export default {
  components: {
    AddStepButton,
  },
  mixins: [translateMixins],
  props: {
    branch: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepChange'],
  computed: {
    steps() {
      return this.branch.steps || [];
    },
    branchName() {
      if (this.branch.name) return this.branch.name;
      if (this.index === -1)
        return this.$t('WGPT_AUTOMATIONS.EDITOR.BRANCH.DEFAULT_BRANCH_NAME');
      return this.$t('WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_BRANCH_NAME', {
        index: this.index + 1,
      });
    },
    isEmpty() {
      return this.steps.length === 0;
    },
  },
  methods: {
    addStepAt(index, newStep) {
      let steps = Array.from(this.steps);
      if (newStep.action === 'branch') {
        newStep.default = {
          ...newStep.default,
          steps: steps.slice(index),
        };
        steps = steps.slice(0, index).concat(newStep);
      } else {
        steps.splice(index, 0, newStep);
      }
      const branch = {
        ...this.branch,
        steps,
      };
      this.$emit('stepChange', this.branch.id, branch);
    },
  },
};
</script>

<template>
  <div class="branch">
    <div class="connector-bridge" />

    <div class="connector-top">
      <div class="branch-label !mx-0 !mt-4 pointer-events-auto">
        <woot-label
          :title="branchName"
          color-scheme="primary"
          class="max-w-[200px] cursor-default !m-0"
        />
      </div>
      <AddStepButton
        class="mt-4 m-0"
        :class="{
          'mb-4': !isEmpty,
          invisible: disabled,
          hidden: disabled && isEmpty,
        }"
        @add-step="e => addStepAt(0, e)"
      />
    </div>

    <div class="steps">
      <slot name="step" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.branch {
  @apply w-max min-w-[216px];
}

.connector-top {
  @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-dashed border-slate-400 dark:border-slate-600;
  --transform: translateX(50%);
}

.connector-bridge {
  @apply w-full border-t-[1px] border-dashed border-slate-400 dark:border-slate-600;
}

.branch-label {
  transform: var(--transform);
}
</style>
