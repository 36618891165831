<script setup>
import Button from 'next/button/Button.vue';
import CardLayout from 'next/CardLayout.vue';
import { computed } from 'vue';

const props = defineProps({
  tool: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['clone', 'edit', 'delete']);

const isAutomationType = computed(() => {
  return props.tool.type === 'function_automation';
});

const onClickClone = () => {
  emit('clone');
};

const onClickEdit = () => {
  emit('edit');
};

const onClickDelete = () => {
  emit('delete');
};
</script>

<template>
  <CardLayout
    class="bg-n-alpha-2 dark:bg-n-solid-3"
    :class="{ 'opacity-50': !isAutomationType }"
  >
    <div class="flex justify-between items-start gap-2">
      <div class="flex flex-col gap-4 flex-1">
        <div class="flex flex-col gap-2">
          <span
            class="text-sm font-medium text-slate-900 dark:text-slate-50 line-clamp-1"
          >
            {{ tool.name }}
          </span>
          <span class="text-sm text-n-slate-11 line-clamp-1 min-h-[1em]">
            {{ tool.description }}
          </span>
        </div>
      </div>
      <div v-if="isAutomationType" class="flex items-center gap-2">
        <Button
          icon="i-lucide-copy"
          variant="faded"
          color="slate"
          size="xs"
          @click="onClickClone"
        />
        <Button
          icon="i-lucide-pencil"
          variant="faded"
          color="slate"
          size="xs"
          @click="onClickEdit"
        />
        <Button
          icon="i-lucide-trash"
          variant="faded"
          color="ruby"
          size="xs"
          @click="onClickDelete"
        />
      </div>
    </div>
  </CardLayout>
</template>
