<script setup>
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables/wgpt';
import { emitter } from 'shared/helpers/mitt';
import { useStore } from 'vuex';
import getUuid from 'widget/helpers/uuid';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import ExecuteCardAutomationForm from './ExecuteCardAutomationForm.vue';

const props = defineProps({
  board: {
    type: Object,
    default: () => ({}),
  },
  list: {
    type: Object,
    default: () => ({}),
  },
  card: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const store = useStore();
const id = getUuid();
const uiFlags = useMapGetter('wgptBoardListCards/getUIFlags');

const isOpen = ref(false);
const dialogRef = ref(null);
const formRef = ref(null);
const automation = ref({});

const handleOpen = () => {
  isOpen.value = true;
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: true });
};

const handleDismiss = () => {
  isOpen.value = false;
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: false });
};

const dismiss = () => {
  dialogRef.value.close();
  handleDismiss();
};

const open = automationData => {
  automation.value = automationData;
  handleOpen();
  nextTick(() => {
    dialogRef.value.open();
  });
};

const onConfirm = async () => {
  try {
    if (!formRef.value) return;
    const { state, v$ } = formRef.value;

    const isFormValid = await v$.$validate();
    if (!isFormValid) return;

    const options = {
      boardId: props.board.id,
      listId: props.list.id,
      cardId: props.card.id,
      id: automation.value.id,
      parameters: state,
    };
    await store.dispatch('wgptBoardListCards/executeAutomation', options);
    dismiss();
    useAlert(
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.API.SUCCESS')
    );
  } catch (error) {
    useAlert(
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.API.ERROR_MESSAGE')
    );
  }
};

defineExpose({ open, dismiss });
</script>

<template>
  <Dialog
    ref="dialogRef"
    :title="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.TITLE')
    "
    :description="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.DESC', {
        automationName: automation.name,
      })
    "
    :confirm-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.EXECUTE')
    "
    :cancel-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.CANCEL')
    "
    :is-loading="uiFlags.isExecuting"
    :disable-confirm-button="uiFlags.isExecuting"
    @confirm="onConfirm"
    @close="handleDismiss"
  >
    <ExecuteCardAutomationForm
      v-if="isOpen"
      ref="formRef"
      :automation="automation"
      @on-confirm="onConfirm"
    />
  </Dialog>
</template>
