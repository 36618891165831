<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getExecutionDetails } from './helpers/campaignHelper';

const props = defineProps({
  now: {
    type: Date,
    default: () => new Date(),
  },
  campaign: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();

const executionDetails = computed(() => {
  const executionConfig = props.campaign.execution_config || {};
  return getExecutionDetails(
    executionConfig.estimated_total_contacts || 0,
    executionConfig
  );
});

const isScheduled = computed(() => {
  const { status, execution_config } = props.campaign;
  if (status !== 'scheduled') return false;

  const executionConfig = execution_config || {};
  const { timezone } = executionConfig;

  if (!executionDetails.value) return false;

  const { startAt } = executionDetails.value;
  const firstSchedule = zonedTimeToUtc(startAt, timezone);

  return props.now >= firstSchedule;
});

const isCompleted = computed(() => {
  const { status, execution_config } = props.campaign;
  if (status !== 'scheduled') return false;

  const executionConfig = execution_config || {};
  const { timezone } = executionConfig;

  if (!executionDetails.value) return false;

  const { endAt } = executionDetails.value;
  const lastSchedule = zonedTimeToUtc(endAt, timezone);

  return props.now > lastSchedule;
});

const campaignStatus = computed(() => {
  const status = props.campaign.status;
  if (status !== 'scheduled') return status || '';

  if (isCompleted.value) return 'completed';
  if (isScheduled.value) return 'processing';

  return 'scheduled';
});

const statusClass = computed(() => {
  const status = campaignStatus.value;
  if (status === 'draft') {
    return 'bg-n-alpha-2 text-n-teal-11';
  }
  if (status === 'scheduled') {
    return 'bg-n-amber-3 text-n-amber-11';
  }
  if (status === 'processing') {
    return 'bg-primary-75 text-primary-800';
  }
  if (status === 'completed') {
    return 'bg-n-teal-3 text-n-teal-11';
  }
  if (status === 'cancelled') {
    return 'bg-n-alpha-2 text-n-slate-11';
  }

  return '';
});

const statusText = computed(() => {
  return t(`WGPT_CAMPAIGNS.CARD.STATUS.${campaignStatus.value.toUpperCase()}`);
});
</script>

<template>
  <span
    class="text-xs font-medium inline-flex items-center h-6 px-2 py-0.5 rounded-md"
    :class="statusClass"
  >
    {{ statusText }}
  </span>
</template>
