<script>
import getUuid from 'widget/helpers/uuid';
import BranchContent from './BranchContent.vue';
import Draggable from 'vuedraggable';

export default {
  components: {
    Draggable,
    BranchContent,
  },
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    variables: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepChange'],
  data() {
    return {
      fallbackName: this.$t(
        'WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_CONDITION_NAME'
      ),
      updatedName: this.step.name,
      isEditing: false,
      dragOptions: {
        animation: 200,
        preventOnFilter: false,
        filter: '.ignore-drag',
      },
    };
  },
  computed: {
    stepName() {
      return this.step.name || this.fallbackName;
    },
    branches: {
      get() {
        return this.step.branches || [];
      },
      set(data) {
        this.$emit('stepChange', this.step.id, { branches: data });
      },
    },
    disabledRemoveBranch() {
      if (this.disabled) return true;
      return this.branches.length <= 1;
    },
    enabled() {
      return !this.disabled;
    },
  },
  watch: {
    step(step, stepBefore) {
      if (step.id !== stepBefore.id || step.name !== this.updatedName) {
        this.updatedName = step.name;
      }
    },
  },
  methods: {
    onBranchChange(branchId, value) {
      const branchesBefore = this.branches;
      const branches = branchesBefore.map(branchBefore => {
        if (branchBefore.id !== branchId) return branchBefore;
        return {
          ...branchBefore,
          ...value,
        };
      });
      const stepUpdates = {
        branches,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    addBranch() {
      const branchesBefore = this.branches;
      const branch = {
        id: getUuid(),
        name: this.$t('WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_BRANCH_NAME', {
          index: branchesBefore.length + 1,
        }),
        logical_operator: 'AND',
        conditions: [
          {
            variable_name: '',
            operator: 'equals',
            value: '',
          },
        ],
        steps: [],
      };
      const branches = branchesBefore.concat(branch);
      const stepUpdates = {
        branches,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    removeBranch(branchId) {
      const branchesBefore = this.branches;
      const branches = branchesBefore.filter(
        branchBefore => branchBefore.id !== branchId
      );
      const stepUpdates = {
        branches,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    onBranchMoveUp(index) {
      const branches = this.branches;
      const branchToMove = branches.splice(index, 1)[0];
      branches.splice(index - 1, 0, branchToMove);
      const stepUpdates = {
        branches,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    onBranchMoveDown(index) {
      const branches = this.branches;
      const branchToMove = branches.splice(index, 1)[0];
      branches.splice(index + 1, 0, branchToMove);
      const stepUpdates = {
        branches,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    editName() {
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
    },
    onNameChange() {
      const stepUpdates = {
        name: this.updatedName,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
      this.isEditing = false;
      this.$refs.nameInput.blur();
    },
    cancelNameEditing() {
      this.isEditing = false;
    },
  },
};
</script>

<template>
  <div class="px-3 pt-4 pb-16">
    <header class="group pe-10">
      <div class="flex items-center gap-2">
        <input
          v-show="isEditing"
          ref="nameInput"
          v-model.trim="updatedName"
          :placeholder="fallbackName"
          class="name-input ignore-drag !ps-1 !bg-transparent disabled:!bg-transparent disabled:!cursor-text !text-slate-900 dark:!text-slate-100 !border-none !outline !outline-2 !outline-transparent -outline-offset-2 !m-0 !font-semibold !h-6"
          dir="auto"
          type="text"
          @blur="onNameChange"
          @keyup.enter="onNameChange"
          @keyup.esc="cancelNameEditing"
        />
        <h5
          v-if="!isEditing"
          class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
        >
          <span class="truncate">{{ stepName }}</span>
          <woot-button
            color-scheme="secondary"
            size="tiny"
            icon="edit"
            variant="clear"
            class-names="invisible group-hover:visible ignore-drag"
            @click="editName"
          />
        </h5>
      </div>
      <p class="text-xs">
        <span>
          {{ $t('WGPT_AUTOMATIONS.REGISTRY.ACTIONS.BRANCH.NAME') }}
        </span>
      </p>
      <p
        class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50"
      >
        {{ $t('WGPT_AUTOMATIONS.REGISTRY.ACTIONS.BRANCH.DESCRIPTION') }}
      </p>
    </header>

    <div>
      <Draggable
        v-model="branches"
        item-key="id"
        v-bind="dragOptions"
        force-fallback
        dir="ltr"
        :disabled="disabled"
      >
        <template #item="{ element: branch, index }">
          <BranchContent
            :branch="branch"
            :variables="variables"
            :index="index"
            :is-first="index === 0"
            :is-last="index === branches.length - 1"
            :disable-remove="disabledRemoveBranch"
            :disabled="disabled"
            class="my-4"
            @branch-change="e => onBranchChange(branch.id, e)"
            @remove="removeBranch(branch.id)"
            @move-up="onBranchMoveUp(index)"
            @move-down="onBranchMoveDown(index)"
          />
        </template>
      </Draggable>
    </div>

    <woot-button
      v-if="enabled"
      class="mt-4"
      color-scheme="primary"
      size="small"
      icon="add"
      @click="addBranch"
    >
      {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.BRANCH.ADD_BRANCH_BUTTON_TEXT') }}
    </woot-button>
  </div>
</template>

<style lang="scss" scoped>
.name-input:focus {
  outline-color: #6cb8ff !important;
}
</style>
