<script>
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import AddStepButton from '../../addStepDropdown/AddStepButton.vue';
import actionValidationMixins from './helpers/actionValidationMixins';
import Dropdown from '../../Dropdown.vue';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    AddStepButton,
    Dropdown,
  },
  mixins: [translateMixins, actionValidationMixins],
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    first: {
      type: Boolean,
      default: true,
    },
    last: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/no-unused-properties
    variables: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addStepBefore', 'stepDelete', 'addStepAfter'],
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    actionRegistry() {
      return this.$store.getters['wgptAutomationRegistry/getAction'](
        this.step.action
      );
    },
  },
};
</script>

<template>
  <div class="action">
    <div v-if="first" class="connector-top">
      <AddStepButton
        class="my-4"
        :class="{ invisible: disabled && false }"
        @add-step="e => $emit('addStepBefore', e)"
      />
    </div>
    <Dropdown class="mx-auto w-max">
      <router-link
        :to="{ query: { selected: step.id } }"
        :class="{
          'step-active': $route.query.selected === step.id,
        }"
        replace
      >
        <div class="action-content" :class="{ error: v$.step.$error }">
          <div
            class="py-2 px-3 border-b border-slate-100 dark:border-slate-600 font-semibold text-sm"
          >
            {{
              step.name ||
              $t('WGPT_AUTOMATIONS.EDITOR.ACTION.FALLBACK_ACTION_NAME')
            }}
          </div>
          <div class="py-2 px-3">
            <p class="text-xs m-0">
              <span v-if="actionRegistry">
                {{ t(actionRegistry.name) }}
              </span>
              <span v-else class="space-y-2 animate-loader-pulse">
                <div class="bg-slate-100 dark:bg-slate-800/50 rounded w-1/2">
                  &nbsp;
                </div>
              </span>
            </p>
          </div>
        </div>
      </router-link>

      <template #popper>
        <div v-if="!disabled" class="flex gap-1">
          <div
            class="bg-white dark:bg-slate-700 rounded-md shadow-sm border border-slate-100 dark:border-slate-600"
          >
            <woot-button
              class="!w-7 !h-7 dark:!bg-slate-800 dark:hover:!bg-slate-900"
              color-scheme="secondary"
              icon="delete"
              size="small"
              variant="clear"
              @click="$emit('stepDelete', step.id)"
            />
          </div>
        </div>
      </template>
    </Dropdown>

    <div class="connector-bottom">
      <AddStepButton
        class="my-4"
        :class="{ 'mb-0': last, invisible: disabled, hidden: disabled && last }"
        @add-step="e => $emit('addStepAfter', e)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-content {
  @apply w-[200px] mx-2 relative bg-white dark:bg-slate-700 rounded-md shadow-sm cursor-pointer border border-slate-400/75 dark:border-slate-600;
}

.step-active > .action-content:not(.error) {
  @apply outline-none;
  outline-color: var(--actionThemeColor);
  outline-offset: 1px;
}

.action-content.error {
  @apply outline-none outline-red-500 dark:outline-red-300;
  outline-offset: 1px;
}

[dir='ltr'] {
  .connector-top,
  .connector-bottom {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-r-[1px] border-dashed border-slate-400 dark:border-slate-600;
    --transform: translateX(50%);
  }
}
[dir='rtl'] {
  .connector-top,
  .connector-bottom {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-l-[1px] border-dashed border-slate-400 dark:border-slate-600;
    --transform: translateX(-50%);
  }
}
</style>
