<script setup>
import { computed, reactive, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, maxLength } from '@vuelidate/validators';
import { useStoreGetters } from 'dashboard/composables/store';
import { alphanumericUnderscore } from '../helpers/rulesHelper';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  toolSet: {
    type: Object,
    default: () => ({}),
  },
  tool: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();
const getters = useStoreGetters();

const tools = computed(() => {
  return getters['wgptBotTools/getTools'].value(props.toolSet.id);
});

const isUniqueName = value => {
  if (!value) return true;
  return !tools.value.some(
    tool => tool.name === value && tool.id !== props.tool.id
  );
};

const rules = {
  name: {
    required,
    alphanumericUnderscore,
    maxLength: maxLength(30),
    isUnique: helpers.withMessage(
      () =>
        t(
          'WGPT_BOTS.EDIT.TABS.TOOLS.LIST.EDITOR.SETTINGS.FORM.NAME.ERROR_UNIQUE'
        ),
      isUniqueName
    ),
  },
  description: {
    required,
  },
};

const state = reactive({
  name: props.modelValue.name || '',
  description: props.modelValue.description || '',
});

const v$ = useVuelidate(rules, state);

const errorMessage = computed(() => {
  if (!v$.value.name.$error) return '';
  if (v$.value.name.alphanumericUnderscore.$invalid) {
    return t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.NAME.ERROR_ALPHANUMERIC'
    );
  }
  if (v$.value.name.maxLength.$invalid) {
    return t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.NAME.ERROR_MAX_LENGTH'
    );
  }
  if (v$.value.name.isUnique.$invalid) {
    return t(
      'WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.NAME.ERROR_UNIQUE'
    );
  }
  return t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.NAME.ERROR');
});

const descriptionErrorMessage = computed(() => {
  if (!v$.value.description.$error) return '';
  return t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.DESCRIPTION.ERROR');
});

watch(
  state,
  newValue => {
    emit('update:modelValue', { ...props.modelValue, ...newValue });
  },
  { deep: true }
);

const onSubmit = () => {
  v$.value.$validate();
};
</script>

<template>
  <div class="px-3 pt-4 pb-16">
    <h5
      class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
    >
      <span class="truncate">{{
        $t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.TITLE')
      }}</span>
    </h5>
    <p class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50">
      {{ $t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.DESCRIPTION') }}
    </p>

    <form class="w-full" @submit.prevent="onSubmit">
      <div>
        <label :class="{ error: v$.name.$error }">
          {{ $t('WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.NAME.LABEL') }}
          <input
            v-model.trim="state.name"
            type="text"
            :disabled="disabled"
            @input="v$.name.$touch"
            @blur="onSubmit"
          />
          <span v-if="errorMessage" class="message">
            {{ errorMessage }}
          </span>
        </label>
      </div>

      <div class="mt-4">
        <label :class="{ error: v$.description.$error }">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.TOOLS.EDITOR.SETTINGS.FORM.DESCRIPTION.LABEL'
            )
          }}
          <textarea
            v-model.trim="state.description"
            :disabled="disabled"
            class="!mb-4"
            @input="v$.description.$touch"
            @blur="onSubmit"
          />
          <span v-if="descriptionErrorMessage" class="message">
            {{ descriptionErrorMessage }}
          </span>
        </label>
      </div>
    </form>
  </div>
</template>
