<script setup>
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue, maxValue } from '@vuelidate/validators';
import { timeZoneOptions } from 'dashboard/routes/dashboard/settings/inbox/helpers/businessHour';
import { zonedTimeToUtc } from 'date-fns-tz';

import Input from 'dashboard/components-next/input/Input.vue';
import ComboBox from 'dashboard/components-next/combobox/ComboBox.vue';

const props = defineProps({
  readonly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit']);
const { t } = useI18n();

const timezoneOptions = timeZoneOptions();

const currentTimeZone = computed(() => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneExist = timezoneOptions.some(({ value }) => {
    return timezone === value;
  });
  if (timeZoneExist) return timezone;

  return 'Etc/UTC';
});

const form = ref({
  start_date: '',
  timezone: currentTimeZone.value,
  start_time: '08:00',
  end_time: '17:00',
  contacts_per_hour: 6,
});

const isStartDatetimeInPast = computed(() => {
  const { start_date, start_time, timezone } = form.value;
  if (!start_date || !start_time || !timezone) return false;

  const selectedDateTimeInTimezone = zonedTimeToUtc(
    `${start_date} ${start_time}`,
    timezone
  );
  const now = new Date();

  return selectedDateTimeInTimezone < now;
});

const rules = props.readonly
  ? {
      start_date: {},
      timezone: {},
      start_time: {},
      end_time: {},
      contacts_per_hour: {},
    }
  : {
      start_date: {
        required,
        validateFutureDate: {
          $validator: () => !isStartDatetimeInPast.value,
          $message: t('WGPT_CAMPAIGNS.START.FORM.START_DATE.PAST_ERROR'),
        },
      },
      timezone: { required },
      start_time: { required },
      end_time: {
        required,
        validateTime: value => {
          const start = new Date(`2000-01-01T${form.value.start_time}`);
          const end = new Date(`2000-01-01T${value}`);
          const diffInHours = (end - start) / (1000 * 60 * 60);
          return diffInHours >= 1;
        },
      },
      contacts_per_hour: {
        minValue: minValue(6),
        maxValue: maxValue(720),
      },
    };

const v$ = useVuelidate(rules, form);

watch(
  () => form.value.start_time,
  () => {
    if (form.value.start_date) {
      v$.value.start_date.$touch();
    }
    if (form.value.end_time) {
      v$.value.end_time.$touch();
    }
  }
);

const setForm = newForm => {
  form.value = { ...newForm };
};

const handleSubmit = () => {
  emit('submit');
};

const validate = () => {
  return v$.value.$validate();
};

const formatTime = timeString => {
  return new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

const endTimeMessage = computed(() => {
  if (!v$.value.end_time.$error) return '';

  if (v$.value.end_time.validateTime.$invalid) {
    const minTime = formatTime(form.value.start_time);
    return t('WGPT_CAMPAIGNS.START.FORM.END_TIME.VALIDATE_ERROR', {
      time: minTime,
    });
  }

  return t('WGPT_CAMPAIGNS.START.FORM.END_TIME.ERROR');
});

defineExpose({ form, validate, setForm });
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="handleSubmit">
    <Input
      v-model="form.start_date"
      type="date"
      :label="t('WGPT_CAMPAIGNS.START.FORM.START_DATE.LABEL')"
      :placeholder="t('WGPT_CAMPAIGNS.START.FORM.START_DATE.PLACEHOLDER')"
      :message="
        v$.start_date.$error
          ? v$.start_date.validateFutureDate.$invalid
            ? t('WGPT_CAMPAIGNS.START.FORM.START_DATE.PAST_ERROR')
            : t('WGPT_CAMPAIGNS.START.FORM.START_DATE.ERROR')
          : t('WGPT_CAMPAIGNS.START.FORM.START_DATE.DESCRIPTION')
      "
      :message-type="v$.start_date.$error ? 'error' : 'info'"
      :disabled="readonly"
      required
      @input="v$.start_date.$touch()"
      @change="v$.start_date.$touch()"
    />

    <div class="flex flex-col min-w-0 gap-1">
      <label class="mb-0.5 text-sm font-medium text-n-slate-12">
        {{ $t('WGPT_CAMPAIGNS.START.FORM.TIMEZONE.LABEL') }}
      </label>
      <ComboBox
        v-model="form.timezone"
        :options="timezoneOptions"
        :placeholder="$t('WGPT_CAMPAIGNS.START.FORM.TIMEZONE.PLACEHOLDER')"
        :has-error="v$.timezone.$error"
        :message="
          v$.timezone.$error
            ? $t('WGPT_CAMPAIGNS.START.FORM.TIMEZONE.ERROR')
            : null
        "
        :disabled="readonly"
        class="[&>div>button]:bg-n-alpha-black2 [&>div>button:not(.focused)]:dark:outline-n-weak [&>div>button:not(.focused)]:hover:!outline-n-slate-6"
        @change="v$.start_date.$touch"
      />
    </div>

    <div class="flex flex-col gap-1">
      <div class="flex gap-4">
        <Input
          v-model="form.start_time"
          type="time"
          :label="t('WGPT_CAMPAIGNS.START.FORM.START_TIME.LABEL')"
          :placeholder="t('WGPT_CAMPAIGNS.START.FORM.START_TIME.PLACEHOLDER')"
          :message="
            v$.start_time.$error
              ? t('WGPT_CAMPAIGNS.START.FORM.START_TIME.ERROR')
              : ''
          "
          :message-type="v$.start_time.$error ? 'error' : 'info'"
          :disabled="readonly"
          required
          class="flex-1"
          @input="v$.end_time.$touch"
        />

        <Input
          v-model="form.end_time"
          type="time"
          :label="t('WGPT_CAMPAIGNS.START.FORM.END_TIME.LABEL')"
          :placeholder="t('WGPT_CAMPAIGNS.START.FORM.END_TIME.PLACEHOLDER')"
          :message="endTimeMessage"
          :message-type="v$.end_time.$error ? 'error' : 'info'"
          :disabled="readonly"
          required
          class="flex-1"
          @input="v$.end_time.$touch"
        />
      </div>
      <p
        class="min-w-0 mt-1 mb-0 text-xs transition-all duration-500 ease-in-out text-n-slate-11 dark:text-n-slate-11"
      >
        {{ t('WGPT_CAMPAIGNS.START.FORM.OPERATING_HOURS.DESCRIPTION') }}
      </p>
    </div>

    <Input
      v-model.number="form.contacts_per_hour"
      type="number"
      :label="t('WGPT_CAMPAIGNS.START.FORM.CONTACTS_PER_HOUR.LABEL')"
      :placeholder="
        t('WGPT_CAMPAIGNS.START.FORM.CONTACTS_PER_HOUR.PLACEHOLDER')
      "
      :message="
        v$.contacts_per_hour.$error
          ? v$.contacts_per_hour.maxValue.$invalid
            ? t('WGPT_CAMPAIGNS.START.FORM.CONTACTS_PER_HOUR.MAX_ERROR')
            : t('WGPT_CAMPAIGNS.START.FORM.CONTACTS_PER_HOUR.ERROR')
          : t('WGPT_CAMPAIGNS.START.FORM.CONTACTS_PER_HOUR.HELP')
      "
      :message-type="v$.contacts_per_hour.$error ? 'error' : 'info'"
      :disabled="readonly"
      class="flex-1"
      @input="v$.contacts_per_hour.$touch"
    />
  </form>
</template>
