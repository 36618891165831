<script setup>
import { useI18n } from 'vue-i18n';
import { useStoreGetters } from 'dashboard/composables/store';

import Button from 'next/button/Button.vue';
import CardLayout from 'next/CardLayout.vue';
import ToolCard from './ToolCard.vue';
import Dropdown from 'next/wgpt/Dropdown/Dropdown.vue';
import DropdownMenu from 'next/dropdown-menu/DropdownMenu.vue';
import { computed } from 'vue';

const props = defineProps({
  toolSet: {
    type: Object,
    required: true,
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'edit',
  'delete',
  'toggle',
  'createTool',
  'cloneTool',
  'editTool',
  'deleteTool',
  'activate',
  'deactivate',
]);
const { t } = useI18n();
const getters = useStoreGetters();

const menuItems = computed(() => {
  const active = props.toolSet.active;
  return [
    {
      label: t(
        `WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.MENU.${active ? 'DEACTIVATE' : 'ACTIVATE'}`
      ),
      action: active ? 'deactivate' : 'activate',
      value: 'edit',
    },
    {
      label: t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.MENU.EDIT'),
      action: 'edit',
      value: 'edit',
    },
    {
      label: t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.MENU.DELETE'),
      action: 'delete',
      value: 'delete',
    },
  ];
});

const tools = computed(() => {
  return getters['wgptBotTools/getTools'].value(props.toolSet.id);
});

const activeText = computed(() => {
  return props.toolSet.active
    ? t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.STATUS.ACTIVE')
    : t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.STATUS.INACTIVE');
});

const activeTextColor = computed(() => {
  return props.toolSet.active ? 'text-n-teal-11' : 'text-n-slate-11';
});

const onClickExpand = () => {
  emit('toggle');
};

const onClickAddTool = () => {
  emit('createTool');
};

const onClickCloneTool = tool => {
  emit('cloneTool', tool);
};

const onClickEditTool = tool => {
  emit('editTool', tool);
};

const onClickDeleteTool = tool => {
  emit('deleteTool', tool);
};

const handleAction = (e, toggle) => {
  switch (e.action) {
    case 'edit':
      emit('edit');
      break;
    case 'delete':
      emit('delete');
      break;
    case 'activate':
      emit('activate');
      break;
    case 'deactivate':
      emit('deactivate');
      break;
    default:
      break;
  }
  toggle();
};
</script>

<template>
  <CardLayout class="my-4">
    <div class="flex justify-between items-start gap-2">
      <div class="flex flex-col gap-4 flex-1">
        <div class="flex flex-col gap-2">
          <span
            class="font-medium text-slate-900 dark:text-slate-50 line-clamp-1"
          >
            {{ toolSet.name }}
          </span>
          <span class="text-sm text-n-slate-11 line-clamp-1 min-h-[1em]">
            {{ toolSet.description }}
          </span>
        </div>
      </div>
      <div class="flex items-center gap-2">
        <span
          class="text-xs font-medium inline-flex items-center h-6 px-2 py-0.5 rounded-md bg-n-alpha-2"
          :class="activeTextColor"
        >
          {{ activeText }}
        </span>
        <Dropdown align="end">
          <template #trigger="{ toggle }">
            <Button
              icon="i-lucide-ellipsis-vertical"
              color="slate"
              size="xs"
              variant="faded"
              class="rounded-md group-hover:bg-n-alpha-2"
              @click="toggle"
            />
          </template>
          <template #default="{ toggle }">
            <DropdownMenu
              class="!static"
              :menu-items="menuItems"
              @action="e => handleAction(e, toggle)"
            />
          </template>
        </Dropdown>
        <Button
          icon="i-lucide-chevron-down"
          variant="faded"
          color="slate"
          size="xs"
          :class="{ 'rotate-180': isExpanded }"
          @click="onClickExpand"
        />
      </div>
    </div>

    <template #after>
      <transition
        enter-active-class="overflow-hidden transition-all duration-300 ease-out"
        leave-active-class="overflow-hidden transition-all duration-300 ease-in"
        enter-from-class="overflow-hidden opacity-0 max-h-0"
        enter-to-class="opacity-100 max-h-[690px] sm:max-h-[470px] md:max-h-[410px]"
        leave-from-class="opacity-100 max-h-[690px] sm:max-h-[470px] md:max-h-[410px]"
        leave-to-class="overflow-hidden opacity-0 max-h-0"
      >
        <div v-show="isExpanded" class="w-full">
          <div class="flex flex-col gap-6 p-6 border-t border-n-strong">
            <div>
              <div class="flex items-center gap-4">
                <span class="py-1 text-sm font-medium text-n-slate-12">{{
                  t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.TOOLS.TITLE')
                }}</span>
                <div class="w-px h-3 truncate bg-n-slate-6" />
                <span class="py-1 text-sm text-n-slate-10">{{
                  t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.TOOLS.COUNT', {
                    count: tools.length,
                  })
                }}</span>
              </div>
              <div class="flex flex-col gap-4 pt-4">
                <ToolCard
                  v-for="tool of tools"
                  :key="tool.id"
                  :tool="tool"
                  @clone="onClickCloneTool(tool)"
                  @edit="onClickEditTool(tool)"
                  @delete="onClickDeleteTool(tool)"
                />
              </div>
            </div>
            <Button
              :label="t('WGPT_BOTS.EDIT.TABS.TOOLS.LIST.CARD.TOOLS.ADD_TOOL')"
              size="sm"
              class="w-max"
              @click="onClickAddTool"
            />
          </div>
        </div>
      </transition>
    </template>
  </CardLayout>
</template>
