<script>
import { mapGetters } from 'vuex';
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import dynamicField from '../dynamicField/Index.vue';
import InputMappingContent from './InputMappingContent.vue';
import InboundWebhookContent from './InboundWebhookContent.vue';

export default {
  components: {
    DynamicField: dynamicField,
    InputMappingContent,
    InboundWebhookContent,
  },
  mixins: [translateMixins],
  props: {
    trigger: {
      type: Object,
      default: () => {},
    },
    disableTriggerChange: {
      type: Boolean,
      default: false,
    },
    disableTriggerAttributes: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['triggerChange'],
  data() {
    return {
      updatedType: this.trigger.type,
    };
  },
  computed: {
    availableVariables() {
      return this.variables.filter(variable => {
        if (variable.source) return true;
        if (variable.wrapper) return true;
        if (!variable.model) return true;
        return false;
      });
    },
    ...mapGetters({
      triggers: 'wgptAutomationRegistry/getTriggers',
    }),
    triggerRegistry() {
      if (!this.updatedType) return {};
      return (
        this.$store.getters['wgptAutomationRegistry/getTrigger'](
          this.updatedType
        ) ?? {}
      );
    },
    fields() {
      return this.triggerRegistry.form_fields || [];
    },
    attributes() {
      return this.trigger.attributes || {};
    },
    triggerOptions() {
      const triggerRegistry = this.triggers[this.trigger.type] || {};
      if (triggerRegistry.managed) {
        return {
          [this.trigger.type]: triggerRegistry,
        };
      }

      return Object.entries(this.triggers)
        .filter(predicate => !predicate[1].managed)
        .reduce((acc, [key, trigger]) => {
          acc[key] = trigger;
          return acc;
        }, {});
    },
    inputMapping() {
      return this.trigger.input_mapping || {};
    },
    inputMappingFields() {
      return this.triggerRegistry.input_mapping_fields || [];
    },
    hasInputMappingFields() {
      return this.inputMappingFields.length > 0;
    },
    isInboundWebhookType() {
      return this.updatedType === 'inbound_webhook';
    },
  },
  watch: {
    trigger(trigger) {
      if (trigger.type !== this.updatedType) {
        this.updatedType = trigger.type;
      }
    },
  },
  methods: {
    onChange(e) {
      const triggerName = e.target.value;
      const trigger = this.triggers[triggerName];
      const triggerUpdates = {
        type: triggerName,
        label: trigger.label,
      };

      const formFields = trigger.form_fields ?? [];
      formFields.forEach(field => {
        if (Object.keys(field).includes('default')) {
          const triggerAttributesBefore = triggerUpdates.attributes ?? {};
          triggerUpdates.attributes = {
            ...triggerAttributesBefore,
            [field.name]: field.default,
          };
        }
      });

      this.$emit('triggerChange', triggerUpdates);
    },
    isVisible(field) {
      const conditions = field.show_if ?? [];
      const results = conditions.map(({ field: fieldName, value }) => {
        const currentValue =
          this.resolveFieldValue(this.attributes, fieldName) ??
          this.getDefaultFieldValue(fieldName);
        if (currentValue == null) return false;
        if (value.includes('*'))
          return `${currentValue}`.includes(value.replace(/\*/g, ''));
        return currentValue === value;
      });
      return results.every(result => result);
    },
    getDefaultFieldValue(fieldName) {
      const field = this.fields.find(({ name }) => name === fieldName);
      return field?.default;
    },
    resolveFieldValue(obj, fieldName) {
      return fieldName.split('.').reduce((prev, curr) => {
        if (prev == null) return prev;
        return prev[curr];
      }, obj);
    },
    onFieldChange(key, value) {
      const attributesBefore = this.trigger?.attributes ?? {};
      const trigger = {
        attributes: {
          ...attributesBefore,
          [key]: value,
        },
      };
      this.$emit('triggerChange', trigger);
    },
    onInputMappingFieldChange(inputMapping) {
      const trigger = {
        input_mapping: inputMapping,
      };
      this.$emit('triggerChange', trigger);
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="trigger">
    <div class="px-3 pt-4 pb-16">
      <h5
        class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
      >
        <span class="truncate">{{
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.TITLE')
        }}</span>
      </h5>
      <p
        class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50"
      >
        {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.DESCRIPTION') }}
      </p>

      <label class="mt-8">
        <select
          v-model="updatedType"
          :disabled="disableTriggerChange"
          class="cursor-pointer disabled:cursor-not-allowed"
          @change="onChange"
        >
          <option value="" disabled selected>
            {{
              $t(
                'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.FORM.TRIGGER_TYPE.DEFAULT_OPTION'
              )
            }}
          </option>
          <option
            v-for="(triggerOption, key) in triggerOptions"
            :key="key"
            :value="key"
          >
            {{ t(triggerOption.label) }}
          </option>
        </select>
      </label>

      <InboundWebhookContent v-if="isInboundWebhookType" />

      <div class="custom-fields">
        <DynamicField
          v-for="(field, index) of fields"
          :key="index"
          :field="field"
          :disabled="disabled || disableTriggerAttributes"
          :value="attributes[field.name]"
          :visible="isVisible(field)"
          :show-variables-selector="false"
          @change="value => onFieldChange(field.name, value)"
        />
      </div>

      <InputMappingContent
        v-if="hasInputMappingFields"
        :input-mapping="inputMapping"
        :input-mapping-fields="inputMappingFields"
        :variables="availableVariables"
        @change="onInputMappingFieldChange"
      />
    </div>
  </div>
</template>
