<script setup>
import { reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { useMapGetter } from 'dashboard/composables/store';

import Input from 'dashboard/components-next/input/Input.vue';
import Button from 'dashboard/components-next/button/Button.vue';
import TextArea from 'dashboard/components-next/textarea/TextArea.vue';

const props = defineProps({
  initialCampaign: {
    type: Object,
    default: null,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['submit', 'cancel']);

const { t } = useI18n();

const formState = {
  uiFlags: useMapGetter('campaigns/getUIFlags'),
};

const initialState = {
  name: props.initialCampaign?.name || '',
  description: props.initialCampaign?.description || '',
};

const state = reactive({ ...initialState });

const rules = {
  name: { required, minLength: minLength(1) },
  description: {},
};

const v$ = useVuelidate(rules, state);

const isCreating = computed(() => formState.uiFlags.value.isCreating);

const isSubmitDisabled = computed(() => v$.value.$invalid);

const resetState = () => {
  Object.assign(state, initialState);
};

const handleCancel = () => emit('cancel');

const prepareCampaignDetails = () => ({
  name: state.name,
  description: state.description,
});

const handleSubmit = async () => {
  const isFormValid = await v$.value.$validate();
  if (!isFormValid) return;

  emit('submit', prepareCampaignDetails());
};

defineExpose({ state, resetState });
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="handleSubmit">
    <Input
      v-model="state.name"
      :label="t('WGPT_CAMPAIGNS.CREATE.FORM.NAME.LABEL')"
      :placeholder="t('WGPT_CAMPAIGNS.CREATE.FORM.NAME.PLACEHOLDER')"
    />

    <TextArea
      v-model="state.description"
      :label="t('WGPT_CAMPAIGNS.CREATE.FORM.DESCRIPTION.LABEL')"
      :placeholder="t('WGPT_CAMPAIGNS.CREATE.FORM.DESCRIPTION.PLACEHOLDER')"
    />

    <div
      v-if="!hideActions"
      class="flex items-center justify-between w-full gap-3"
    >
      <Button
        variant="faded"
        color="slate"
        type="button"
        :label="t('WGPT_CAMPAIGNS.CREATE.FORM.BUTTONS.CANCEL')"
        class="w-full bg-n-alpha-2 n-blue-text hover:bg-n-alpha-3"
        @click="handleCancel"
      />
      <Button
        :label="t('WGPT_CAMPAIGNS.CREATE.FORM.BUTTONS.CREATE')"
        class="w-full"
        type="submit"
        :is-loading="isCreating"
        :disabled="isCreating || isSubmitDisabled"
      />
    </div>
  </form>
</template>
