<script>
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import variablesMixins from '../variablesDropdown/variablesMixins';
import WgptFluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';
import { requiredIf, numeric, or } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { containsVariable } from './helpers/validations';
import VariablesDropdown from '../variablesDropdown/Index.vue';

export default {
  components: {
    WgptFluentIcon,
    VariablesDropdown,
  },
  mixins: [variablesMixins, translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedValue: this.value ?? this.defaultValue,
    };
  },
  validations() {
    return {
      updatedValue: {
        required: requiredIf(this.required),
        numeric: or(numeric, containsVariable),
        min: value => {
          if (containsVariable(value)) return true;
          if (this.min === undefined) return true;
          return value >= this.min;
        },
        max: value => {
          if (containsVariable(value)) return true;
          if (this.max === undefined) return true;
          return value <= this.max;
        },
      },
    };
  },
  watch: {
    value(value) {
      this.updatedValue = value;
    },
  },
  methods: {
    updateValue() {
      this.v$.$touch();

      const updatedValue = containsVariable(this.updatedValue)
        ? this.updatedValue
        : Number(this.updatedValue);

      this.$emit('change', updatedValue);
    },
    onVariableAdd(updatedValue) {
      this.updatedValue = updatedValue;
      this.updateValue();
    },
    onInput(event) {
      let value = event.target.value;

      if (value === '-') return;
      if (containsVariable(value)) return;

      while (Number.isNaN(Number(value))) {
        value = value.slice(0, value.length - 1);
        this.updatedValue = value;
      }
    },
  },
};
</script>

<template>
  <div>
    <label :class="{ error: v$.updatedValue.$error }">
      <div class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </div>

      <div class="flex relative">
        <!-- eslint-disable -->
        <input
          ref="fieldEl"
          v-model.trim="updatedValue"
          type="text"
          class="input peer"
          :class="{ 'focus:!pe-10': showVariablesSelector }"
          :min="min"
          :max="max"
          :placeholder="t(placeholder)"
          :disabled="disabled"
          @change="updateValue"
          @input="onInput"
          @keyup="openVariablesDropdown"
        />
        <!--eslint-enable-->

        <woot-button
          v-if="showVariablesSelector"
          size="tiny"
          color-scheme="secondary"
          variant="smooth"
          class-names="button--only-icon !p-1 shadow-sm absolute top-0 end-0 m-2 invisible peer-focus:visible active:visible focus:visible"
          @click="openVariablesDropdown"
        >
          <WgptFluentIcon icon="braces-variable" size="14" />
        </woot-button>
      </div>

      <VariablesDropdown
        :show-dropdown="showVariablesDropdown"
        :event="variablesDropdownEvent"
        :variables="variables"
        :filter-by-prefix="variablesFilter"
        @close="closeVariablesDropdown"
        @add="addVariable"
      />

      <span v-if="v$.updatedValue.$error" class="message">
        <template v-if="v$.updatedValue.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else-if="v$.updatedValue.min.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.MIN', { min: min })
        }}</template>
        <template v-else-if="v$.updatedValue.max.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.MAX', { max: max })
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.button--only-icon ::v-deep * {
  @apply pointer-events-none;
}

.error input {
  @apply border-red-500;
}

:is(.dark .error input) {
  @apply border-red-500;
}
</style>
