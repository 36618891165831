<script setup>
import { ref } from 'vue';
import { useStore, useStoreGetters } from 'dashboard/composables/store';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';

const props = defineProps({
  selectedCampaign: {
    type: Object,
    default: null,
  },
});

const store = useStore();
const getters = useStoreGetters();
const { t } = useI18n();

const dialogRef = ref(null);
const uiFlags = getters['wgptCampaigns/getUIFlags'];

const handleDialogConfirm = async () => {
  const id = props.selectedCampaign?.id;
  if (!id) return;

  try {
    await store.dispatch('wgptCampaigns/delete', id);
    useAlert(t('WGPT_CAMPAIGNS.DELETE.API.SUCCESS_MESSAGE'));
  } catch (error) {
    useAlert(t('WGPT_CAMPAIGNS.DELETE.API.ERROR_MESSAGE'));
  } finally {
    dialogRef.value?.close();
  }
};

defineExpose({ dialogRef });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="alert"
    :title="t('WGPT_CAMPAIGNS.DELETE.TITLE')"
    :description="t('WGPT_CAMPAIGNS.DELETE.DESCRIPTION')"
    :confirm-button-label="t('WGPT_CAMPAIGNS.DELETE.CONFIRM')"
    :is-loading="uiFlags.isDeleting"
    @confirm="handleDialogConfirm"
  />
</template>
