<script>
import translateMixins from 'dashboard/routes/dashboard/settings/wgptAutomations/mixins/translateMixins';
import { useVuelidate } from '@vuelidate/core';

export default {
  mixins: [translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    optionLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedValue: this.value ?? this.defaultValue,
    };
  },
  validations() {
    return {
      updatedValue: {
        customValidator(value) {
          if (this.required === undefined) {
            return true;
          }
          if (this.required === true) {
            return value === true;
          }
          return true;
        },
      },
    };
  },
  watch: {
    value(value) {
      this.updatedValue = value;
    },
  },
  methods: {
    updateValue() {
      this.v$.$touch();
      this.$emit('change', this.updatedValue);
    },
  },
};
</script>

<template>
  <div>
    <div class="input-label" :class="{ error: v$.updatedValue.$error }">
      <div class="flex items-center gap-2">
        <label v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </label>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </div>

      <label class="my-3 flex items-center">
        <input
          v-model="updatedValue"
          type="checkbox"
          class="me-2 !mb-0"
          name="toggle-imap-enable"
          :disabled="disabled"
          @change="updateValue"
        />
        <span v-if="optionLabel">{{ t(optionLabel) }}</span>
      </label>

      <span v-if="v$.updatedValue.$error" class="message">
        <template v-if="v$.updatedValue.customValidator.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button--only-icon ::v-deep * {
  @apply pointer-events-none;
}

.error input {
  @apply border-red-500;
}

:is(.dark .error input) {
  @apply border-red-500;
}
</style>
