<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getExecutionDetails } from './helpers/campaignHelper';

import CampaignStatusLabel from './CampaignStatusLabel.vue';
import Button from 'dashboard/components-next/button/Button.vue';
import CardLayout from 'dashboard/components-next/CardLayout.vue';

const props = defineProps({
  now: {
    type: Date,
    default: () => new Date(),
  },
  campaign: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['edit', 'delete', 'clone', 'stop']);

const { t } = useI18n();

const getCampaignExecutionDetails = (executionConfig = {}) => {
  const executionDetails = getExecutionDetails(
    executionConfig.estimated_total_contacts || 0,
    executionConfig
  );

  return executionDetails;
};

const executionDetails = computed(() => {
  return getCampaignExecutionDetails(props.campaign.execution_config);
});

const isCompleted = computed(() => {
  const { status, execution_config } = props.campaign;
  if (status !== 'scheduled') return false;

  const executionConfig = execution_config || {};
  const { timezone } = executionConfig;

  if (!executionDetails.value) return false;

  const { endAt } = executionDetails.value;
  const lastSchedule = zonedTimeToUtc(endAt, timezone);

  return props.now > lastSchedule;
});

const canDelete = computed(() => {
  if (props.campaign.status !== 'scheduled') return true;

  return isCompleted.value;
});

const timezone = computed(() => {
  return props.campaign.execution_config?.timezone || '';
});

const scheduledAt = computed(() => {
  if (!executionDetails.value) return '';

  const { startAt } = executionDetails.value;

  return format(startAt, `LLL d, h:mm a`);
});

const scheduledUntil = computed(() => {
  if (!executionDetails.value) return '';

  const { endAt } = executionDetails.value;

  return format(endAt, `LLL d, h:mm a`);
});

const showSchedule = computed(() => {
  return scheduledAt.value && scheduledUntil.value;
});
</script>

<template>
  <CardLayout layout="row" class="min-h-[124px]">
    <div class="flex flex-col items-start justify-between flex-1 min-w-0 gap-2">
      <div class="flex justify-between gap-3 w-fit">
        <span
          class="text-base font-medium capitalize text-n-slate-12 line-clamp-1"
        >
          {{ campaign.name }}
        </span>
        <CampaignStatusLabel :campaign="campaign" :now="now" />
      </div>
      <div class="text-sm text-n-slate-11 line-clamp-1 [&amp;>p]:mb-0 h-6">
        <p>{{ campaign.description }}</p>
      </div>

      <div v-if="showSchedule" class="flex gap-1.5 items-center w-full">
        <span class="text-sm font-medium truncate text-n-slate-10 shrink-0">
          {{ t('WGPT_CAMPAIGNS.CARD.CAMPAIGN_DETAILS.FROM') }}
        </span>
        <span class="text-sm font-medium truncate text-n-slate-12 shrink-0">
          {{ scheduledAt }}
        </span>
        <span class="text-sm font-medium truncate text-n-slate-10 shrink-0">
          {{ t('WGPT_CAMPAIGNS.CARD.CAMPAIGN_DETAILS.UNTIL') }}
        </span>
        <span class="text-sm font-medium truncate text-n-slate-12 shrink-0">
          {{ scheduledUntil }}
        </span>
      </div>

      <div>
        <span class="text-xs font-medium truncate text-n-slate-10 shrink-0">
          {{ timezone }}
        </span>
      </div>
    </div>
    <div class="flex items-center justify-end w-20 gap-2 mb-auto -mt-1">
      <Button
        variant="faded"
        size="sm"
        color="slate"
        icon="i-lucide-pencil"
        class="shrink-0"
        @click="emit('edit')"
      />
      <Button
        variant="faded"
        size="sm"
        color="slate"
        icon="i-lucide-copy"
        class="shrink-0"
        @click="emit('clone')"
      />
      <Button
        v-if="canDelete"
        variant="faded"
        color="ruby"
        size="sm"
        icon="i-lucide-trash"
        class="shrink-0"
        @click="emit('delete')"
      />
      <Button
        v-else
        variant="faded"
        color="ruby"
        size="sm"
        icon="i-lucide-circle-stop"
        class="shrink-0"
        @click="emit('stop')"
      />
    </div>
  </CardLayout>
</template>
