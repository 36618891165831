import { frontendURL } from 'dashboard/helper/URLHelper.js';

import CampaignsPageRouteView from '../CampaignsPageRouteView.vue';
import CampaignsPage from './CampaignsPage.vue';
import CampaignEditorPage from './edit/CampaignEditorPage.vue';

const wgptCampaignsRoutes = {
  routes: [
    {
      path: frontendURL('accounts/:accountId/wgpt-campaigns'),
      component: CampaignsPageRouteView,
      children: [
        {
          path: '',
          name: 'wgpt_campaigns_index',
          meta: {
            permissions: ['administrator'],
          },
          component: CampaignsPage,
        },
        {
          path: ':campaignId',
          name: 'wgpt_campaigns_editor',
          meta: {
            permissions: ['administrator'],
          },
          component: CampaignEditorPage,
        },
      ],
    },
  ],
};

export default wgptCampaignsRoutes;
