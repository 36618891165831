<script setup>
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables';
import { useMapGetter } from 'dashboard/composables/store.js';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const currentAccountId = useMapGetter('getCurrentAccountId');

const url = computed(() => {
  const domain = window.location.origin;
  const accountId = currentAccountId.value;
  const automationId = route.params.automationId;
  return `${domain}/wgpt/webhooks/automations/${accountId}/${automationId}`;
});

const copyToClipboard = () => {
  navigator.clipboard.writeText(url.value);
  useAlert(
    t(
      'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.FORM.INBOUND_WEBHOOK.COPY_SUCCESSFUL'
    )
  );
};
</script>

<template>
  <label class="input-label">
    <div class="flex items-center gap-2">
      <div class="block truncate">
        {{
          t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.FORM.INBOUND_WEBHOOK.LABEL'
          )
        }}
      </div>
      <fluent-icon
        v-tooltip.top="{
          content: t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.FORM.INBOUND_WEBHOOK.DESCRIPTION'
          ),
          popperClass: 'max-w-[300px]',
          boundary: 'body',
        }"
        icon="info"
        size="14"
      />
    </div>
    <div class="flex relative">
      <input v-model.trim="url" class="input peer" type="text" readonly />

      <woot-button
        size="tiny"
        color-scheme="secondary"
        variant="smooth"
        class-names="button--only-icon !p-1 shadow-sm absolute top-0 end-0 m-2"
        @click="copyToClipboard"
      >
        <fluent-icon icon="clipboard" size="14" />
      </woot-button>
    </div>
  </label>
</template>
