<script setup>
import { ref, computed } from 'vue';
import { requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import ExecuteCardAutomationFormField from './ExecuteCardAutomationFormField.vue';

const props = defineProps({
  automation: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['confirm']);

const formState = ref({});
const formRules = {};

const parameterFields = computed(() => {
  return props.automation.parameters || [];
});

parameterFields.value.forEach(field => {
  formState.value[field.variable_name] = { value: '' };
  formRules[field.variable_name] = {
    required: requiredIf(() => {
      if (field.type === 'boolean') return false;
      return field.required;
    }),
  };
});

const castParameterValue = (value, type) => {
  if (type === 'number') {
    if (value === '') return null;
    return Number(value);
  }
  if (type === 'boolean') {
    return !!value;
  }
  return `${value}`;
};

const state = computed(() => {
  const data = {};
  parameterFields.value.forEach(field => {
    const value = formState.value[field.variable_name].value;
    const type = field.type;
    data[field.variable_name] = castParameterValue(value, type);
  });
  return data;
});

const v$ = useVuelidate(formRules, formState);

const onConfirm = () => {
  emit('confirm');
};

defineExpose({ state, v$ });
</script>

<template>
  <form class="space-y-4" @submit.prevent="onConfirm">
    <div
      v-for="field of parameterFields"
      :key="field.variable_name"
      class="form-field"
    >
      <ExecuteCardAutomationFormField
        v-model="formState[field.variable_name]"
        :type="field.type"
        :label="field.label"
        :description="field.description"
        :required="field.required == true"
        :rules="formRules[field.variable_name]"
      />
    </div>
  </form>
</template>
