<script>
import {
  fullSchema,
  buildEditor,
  EditorView,
  MessageMarkdownTransformer,
  ArticleMarkdownSerializer,
  ArticleMarkdownTransformer,
  EditorState,
} from '@chatwoot/prosemirror-schema';
import {
  suggestionsPlugin,
  triggerCharacters,
} from '@chatwoot/prosemirror-schema/src/mentions/plugin';
import {
  insertAtCursor,
  scrollCursorIntoView,
  getContentNode,
} from 'dashboard/helper/editorHelper';
import { useUISettings } from 'dashboard/composables/useUISettings';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import KeyboardEmojiSelector from 'dashboard/components/widgets/WootWriter/keyboardEmojiSelector.vue';
import Dropdown from '../../../../components/Dropdown.vue';
import variablesMixins from '../../variablesDropdown/variablesMixins';
import WgptFluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';
import EmojiInput from 'shared/components/emoji/EmojiInput.vue';
import VariablesDropdown from '../../variablesDropdown/Index.vue';
import { WGPT_AUTOMATIONS_RICHTEXT_EDITOR_MENU_OPTIONS } from '../helpers/constants';

const createState = (
  content,
  placeholder,
  // eslint-disable-next-line default-param-last
  plugins = [],
  // eslint-disable-next-line default-param-last
  methods = {},
  enabledMenuOptions = WGPT_AUTOMATIONS_RICHTEXT_EDITOR_MENU_OPTIONS
) => {
  // Adds '\' before '.' (e.g. %{contact.name} => %{contact\.name})
  const safeContent = content.replace(
    /%\{([^{}]*?\.[^{}]*?)\}/g,
    (match, p1) => {
      const escapedVariable = p1.replace(/\./g, '\\.');
      return `%{${escapedVariable}}`;
    }
  );
  return EditorState.create({
    doc: new ArticleMarkdownTransformer(fullSchema).parse(safeContent),
    plugins: buildEditor({
      schema: fullSchema,
      placeholder,
      methods,
      plugins,
      enabledMenuOptions,
    }),
  });
};

let editorView = null;
let state;

export default {
  components: {
    KeyboardEmojiSelector,
    Dropdown,
    WgptFluentIcon,
    EmojiInput,
    VariablesDropdown,
  },
  mixins: [variablesMixins, keyboardEventListenerMixins],
  props: {
    modelValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    variables: { type: Array, default: () => [] },
  },
  emits: ['blur', 'input', 'update:modelValue', 'keyup', 'focus', 'keydown'],
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();

    return {
      uiSettings,
      updateUISettings,
    };
  },
  data() {
    return {
      isTextSelected: false, // Tracks text selection and prevents unnecessary re-renders on mouse selection
      showEmojiMenu: false,
      emojiSearchTerm: '',
      editorView: null,
      updateSelectionWith: '',
      range: {
        from: 1,
        to: 2,
      },
      state: undefined,
      enabledSuggestion: true,
    };
  },
  computed: {
    plugins() {
      return [
        suggestionsPlugin({
          matcher: triggerCharacters('%'),
          suggestionClass: '',
          onEnter: args => this.onSuggestionsPluginEnter(args, '%'),
          onChange: this.onSuggestionsPluginChange,
          onExit: this.onSuggestionsPluginExit,
          onKeyDown: this.onSuggestionsPluginKeyDown,
        }),
        suggestionsPlugin({
          matcher: triggerCharacters('#'),
          suggestionClass: '',
          onEnter: args => this.onSuggestionsPluginEnter(args, '#'),
          onChange: this.onSuggestionsPluginChange,
          onExit: this.onSuggestionsPluginExit,
          onKeyDown: this.onSuggestionsPluginKeyDown,
        }),
        suggestionsPlugin({
          matcher: triggerCharacters(':', 2), // Trigger after ':' and at least 2 characters
          suggestionClass: '',
          onEnter: args => {
            if (!this.enabledSuggestion) return false;

            this.showEmojiMenu = true;
            this.emojiSearchTerm = args.text || '';
            this.range = args.range;
            editorView = args.view;
            this.variablesDropdownEvent = {
              target: editorView.dom,
              eventName: 'suggestion',
            };
            return false;
          },
          onChange: args => {
            editorView = args.view;
            this.range = args.range;
            this.emojiSearchTerm = args.text;
            return false;
          },
          onExit: () => {
            this.emojiSearchTerm = '';
            this.showEmojiMenu = false;
            return false;
          },
          onKeyDown: ({ event }) => {
            return event.keyCode === 13 && this.showEmojiMenu;
          },
        }),
      ];
    },
  },
  watch: {
    modelValue(newValue = '') {
      if (newValue !== this.contentFromEditor()) {
        this.reloadState();
      }
    },
    updateSelectionWith(value, valueBefore) {
      if (!editorView) return;

      if (value !== valueBefore && value !== '') {
        this.enabledSuggestion = false;
        const node = editorView.state.schema.text(value);
        const tr = editorView.state.tr.replaceSelectionWith(node);
        editorView.focus();
        state = editorView.state.apply(tr);
        editorView.updateState(state);
        this.emitOnChange();
        this.updateSelectionWith = '';
        this.enabledSuggestion = true;
      }
    },
  },

  created() {
    state = createState(this.modelValue, this.placeholder, this.plugins);
  },
  mounted() {
    this.createEditorView();

    editorView.updateState(state);
  },
  methods: {
    contentFromEditor() {
      if (editorView) {
        return ArticleMarkdownSerializer.serialize(editorView.state.doc);
      }
      return '';
    },
    reloadState() {
      state = createState(this.modelValue, this.placeholder, this.plugins);
      editorView.updateState(state);
    },
    createEditorView() {
      editorView = new EditorView(this.$refs.editor, {
        state: state,
        dispatchTransaction: tx => {
          state = state.apply(tx);
          editorView.updateState(state);
          if (tx.docChanged) {
            this.emitOnChange();
          }
          this.checkSelection(state);
        },
        handleDOMEvents: {
          keyup: this.onKeyup,
          focus: this.onFocus,
          blur: this.onBlur,
          keydown: this.onKeydown,
          paste: (view, event) => {
            const data = event.clipboardData.files;
            if (data.length > 0) {
              data.forEach(file => {
                // Check if the file is an image
                if (file.type.includes('image')) {
                  /* empty */
                }
              });
              event.preventDefault();
            }
          },
        },
      });
    },
    insertSpecialContent(type, content) {
      if (!editorView) {
        return;
      }

      let { node, from, to } = getContentNode(
        editorView,
        type,
        content,
        this.range,
        {}
      );

      if (!node) return;

      this.insertNodeIntoEditor(node, from, to);
    },
    emitOnChange() {
      this.$emit('update:modelValue', this.contentFromEditor());
      this.$emit('input', this.contentFromEditor());
    },
    onKeyup() {
      this.$emit('keyup');
    },
    onKeydown() {
      this.$emit('keydown');
    },
    onBlur() {
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    checkSelection(editorState) {
      const { from, to } = editorState.selection;
      // Check if there's a selection (from and to are different)
      const hasSelection = from !== to;
      // If the selection state is the same as the previous state, do nothing
      if (hasSelection === this.isTextSelected) return;
      // Update the selection state
      this.isTextSelected = hasSelection;

      const { editor } = this.$refs;

      // Toggle the 'has-selection' class based on whether there's a selection
      editor.classList.toggle('has-selection', hasSelection);
      // If there's a selection, update the menubar position
      if (hasSelection) this.setMenubarPosition(editorState);
    },
    setMenubarPosition(editorState) {
      if (!editorState.selection) return;

      // Get the start and end positions of the selection
      const { from, to } = editorState.selection;
      const { editor } = this.$refs;
      // Get the editor's position relative to the viewport
      const { left: editorLeft, top: editorTop } =
        editor.getBoundingClientRect();

      // Get the editor's width
      const editorWidth = editor.offsetWidth;
      const menubarWidth = 480; // Menubar width (adjust as needed (px))

      // Get the end position of the selection
      const { bottom: endBottom, right: endRight } = editorView.coordsAtPos(to);
      // Get the start position of the selection
      const { left: startLeft } = editorView.coordsAtPos(from);

      // Calculate the top position for the menubar (10px below the selection)
      const top = endBottom - editorTop + 10;
      // Calculate the left position for the menubar
      // This centers the menubar on the selection while keeping it within the editor's bounds
      const left = Math.max(
        0,
        Math.min(
          (startLeft + endRight) / 2 - editorLeft,
          editorWidth - menubarWidth
        )
      );
      // Set the CSS custom properties for positioning the menubar
      editor.style.setProperty('--selection-top', `${top}px`);
      editor.style.setProperty('--selection-left', `${left}px`);
    },
    insertContentIntoEditor(content, defaultFrom = 0) {
      const from = defaultFrom || editorView.state.selection.from || 0;
      let node = new MessageMarkdownTransformer(fullSchema).parse(content);

      this.insertNodeIntoEditor(node, from, undefined);
    },
    insertNodeIntoEditor(node, from = 0, to = 0) {
      state = insertAtCursor(editorView, node, from, to);
      this.emitOnChange();
      this.$nextTick(() => {
        scrollCursorIntoView(editorView);
      });
    },
    onVariableAdd(_updatedValue, variableName, variablePrefix) {
      if (!editorView) return;
      if (this.variablesDropdownEvent.eventName === 'suggestion') {
        const node = editorView.state.schema.text(
          `${variablePrefix}{${variableName}}`
        );
        const { from, to } = this.range;
        this.insertNodeIntoEditor(node, from, to);
        return;
      }

      this.updateSelectionWith = `${variablePrefix}{${variableName}}`;
    },
    openVariablesDropdownManually() {
      const event = {
        target: editorView.dom,
        eventName: 'click',
      };
      this.openVariablesDropdown(event);
    },
    openEmojisDropdownManually(e) {
      this.variablesDropdownEvent = e;
      this.showEmojiMenu = true;
    },
    hideEmojiPickerManually() {
      this.showEmojiMenu = false;
    },
    addIntoEditor(content) {
      this.updateSelectionWith = content;
    },
    onSuggestionsPluginEnter(args, char) {
      if (!this.enabledSuggestion) return false;

      this.showVariablesDropdown = true;
      this.variablesFilter = char;
      this.range = args.range;
      editorView = args.view;
      this.variablesDropdownEvent = {
        target: args.view.dom,
        eventName: 'suggestion',
      };
      return false;
    },
    onSuggestionsPluginChange(args) {
      editorView = args.view;
      this.range = args.range;

      this.variableSearchTerm = args.text;
      return false;
    },
    onSuggestionsPluginExit() {
      this.variableSearchTerm = '';
      this.showVariablesDropdown = false;
      return false;
    },
    onSuggestionsPluginKeyDown({ event }) {
      return event.keyCode === 13 && this.showVariablesDropdown;
    },
  },
};
</script>

<template>
  <div class="editor-root">
    <Dropdown
      v-if="emojiSearchTerm"
      :show-dropdown="showEmojiMenu"
      :event="variablesDropdownEvent"
      :show-backdrop="false"
      @close="showEmojiMenu = false"
    >
      <KeyboardEmojiSelector
        v-if="showEmojiMenu"
        class="w-max max-w-[300px]"
        :search-key="emojiSearchTerm"
        @select-emoji="emoji => insertSpecialContent('emoji', emoji)"
      />
    </Dropdown>
    <Dropdown
      v-else
      :show-dropdown="showEmojiMenu"
      :event="variablesDropdownEvent"
      position="start"
      class="emoji-dropdown"
      @close="showEmojiMenu = false"
    >
      <EmojiInput
        v-if="showEmojiMenu"
        class="emoji-input !top-0 !right-0 !static"
        :on-click="addIntoEditor"
      />
    </Dropdown>

    <div ref="editor" />

    <div class="py-3 z-10 flex gap-2">
      <woot-button
        v-tooltip.top-end="
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.TIP_EMOJI_ICON')
        "
        :title="
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.TIP_EMOJI_ICON')
        "
        icon="emoji"
        emoji="😊"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="openEmojisDropdownManually"
      />
      <woot-button
        v-tooltip.top-end="
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.TIP_VARIABLE_ICON')
        "
        :title="
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.TIP_VARIABLE_ICON')
        "
        size="small"
        color-scheme="secondary"
        variant="smooth"
        class-names="button--only-icon !p-1 shadow-sm *:w-max"
        @click="openVariablesDropdownManually"
      >
        <WgptFluentIcon icon="braces-variable" size="14" />
      </woot-button>
    </div>

    <VariablesDropdown
      :show-dropdown="showVariablesDropdown"
      :event="variablesDropdownEvent"
      :variables="variables"
      :filter-by-prefix="variablesFilter"
      @close="showVariablesDropdown = false"
      @add="addVariable"
    />
  </div>
</template>

<style lang="scss" scoped>
.button--only-icon ::v-deep {
  .button__content,
  .icon {
    @apply w-max pointer-events-none;
  }
}

.emoji-dropdown ::v-deep .dropdown-pane {
  @apply mx-2;
}

.emoji-input::before {
  right: -1em;
  transform: rotate(-90deg);
  bottom: 0.75rem;
  display: none;
}
</style>
