<script setup>
import { onUnmounted, ref } from 'vue';
import CampaignCard from './CampaignCard.vue';

defineProps({
  campaigns: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['edit', 'delete', 'clone', 'stop']);

const now = ref(new Date());
const timer = setInterval(() => {
  now.value = new Date();
}, 1000);

onUnmounted(() => {
  clearInterval(timer);
});

const handleDelete = campaign => emit('delete', campaign);
const handleEdit = campaign => emit('edit', campaign);
const handleClone = campaign => emit('clone', campaign);
const handleStop = campaign => emit('stop', campaign);
</script>

<template>
  <div class="flex flex-col gap-4">
    <CampaignCard
      v-for="campaign in campaigns"
      :key="campaign.id"
      :campaign="campaign"
      :now="now"
      @edit="handleEdit(campaign)"
      @delete="handleDelete(campaign)"
      @clone="handleClone(campaign)"
      @stop="handleStop(campaign)"
    />
  </div>
</template>
